<template>
  <n-config-provider :theme="theme">
    <n-dialog-provider>
      <n-layout>
        <n-layout-content>
          <div class="app_container">
            <Login :data="data.isLoggedIn" v-if="!data.isLoggedIn">
              <slot />
            </Login>
            <Dashboard v-if="data.isLoggedIn" :role="data.user.role">
              <slot
            /></Dashboard>
          </div>
        </n-layout-content>
      </n-layout>
    </n-dialog-provider>
  </n-config-provider>
</template>

<script>
import { computed, h, reactive, ref, watch, onMounted } from "vue";
import Login from "./login.vue";
import { useStore } from "vuex";
import Dashboard from "./dashboard.vue";
import { NThemeEditor, useMessage, NAlert, darkTheme } from "naive-ui";
import { useRoute, useRouter } from "vue-router";
const renderMessage = (props) => {
  const { type } = props;
  return h(
    NAlert,
    {
      closable: props.closable,
      onClose: props.onClose,
      type: type === "loading" ? "default" : type,
      title: "Error Occurred",
      style: {
        boxShadow: "var(--n-box-shadow)",
        maxWidth: "calc(100vw - 32px)",
        width: "480px",
      },
    },
    {
      default: () => props.content,
    }
  );
};
const renderSuccessMessage = (props) => {
  const { type } = props;
  return h(
    NAlert,
    {
      closable: props.closable,
      onClose: props.onClose,
      type: type === "loading" ? "default" : type,
      title: "Action submitted successfully",
      style: {
        boxShadow: "var(--n-box-shadow)",
        maxWidth: "calc(100vw - 32px)",
        width: "480px",
      },
    },
    {
      default: () => props.content,
    }
  );
};
var EmployeeRoutes = ["Calendar", "my-holidays", "home"];

var AdminRoutes = [
  "Calendar",
  "users",
  "home",
  "staff-leave",
  "Archived users",
  "my-holidays",
  "customer-payment"
];

var allRoutes = ["request-rest", "login", "update-rest"];
export default {
  setup() {
    const { error, success } = useMessage();
    const router = useRouter();
    const route = useRoute();
    var theme = ref(null);
    const store = useStore();
    const data = reactive({
      loading: computed(() => store.state.loading),
      isLoggedIn: computed(() => store.state.isLoggedIn),
      hasError: computed(() => store.state.hasError),
      error_message: computed(() => store.state.error_message),
      error_title: computed(() => store.state.error_title),
      themeMode: computed(() => store.state.themeMode),
      user: computed(() => store.state.user),
      hasMessage: computed(() => store.state.hasMessage),
      message: computed(() => store.state.message),
    });

    onMounted(async () => {
      checkLoggedIn();
    });
    var checkLoggedIn = async () => {
      if (allRoutes.includes(route.name)) return;
      var result = await store.dispatch("Me");
      if (!result) {
        if (allRoutes.includes(route.name)) {
          router.push(route.fullPath);
        } else {
          router.push("/login");
        }
      } else {
        await store.dispatch("fetchNotifications");
        if (route.name == "login") router.push("/");
        else if (
          (data.user.role == "employee") &
            !EmployeeRoutes.includes(route.name) ||
          (data.user.role == "admin") & !AdminRoutes.includes(route.name)
        )
          router.push("/403-forbidden");
        else router.push(route.fullPath);
      }
    };

    if (localStorage.getItem("mode") == "dark") {
      theme.value = darkTheme;
      store.dispatch("changeTheme", localStorage.getItem("mode"));
    } else {
      store.dispatch("changeTheme", localStorage.getItem("mode"));
    }
    watch(() => {
      if (data.themeMode == "dark") {
        theme.value = darkTheme;
      } else {
        theme.value = null;
      }
      if (data.hasError) {
        error(data.error_message, {
          render: renderMessage,
          closable: true,
        });
        setTimeout(() => {
          store.dispatch("emptyMessages");
        }, 3500);
      }
      if (data.hasMessage) {
        success(data.message, {
          render: renderSuccessMessage,
          closable: true,
        });
        setTimeout(() => {
          store.dispatch("emptyMessages");
        }, 3500);
      }
    });
    return {
      data,
      theme,
    };
  },
  components: { Login, Dashboard, NThemeEditor },
};
</script>

<style lang="scss" scoped></style>
