import axios from "axios";
export const BASE_URL = process.env.VUE_APP_BASE_LOOKER_STUDIO_API;
const secretKey = process.env.VUE_APP_BASE_JWT_SECRET || "brandclubJWTSECRET";
function utf8ToBase64(str) {
  return btoa(unescape(encodeURIComponent(str)));
}
function generateToken() {
  let encrypted = "";
  let keyLength = secretKey.length;
  var data = new Date() + secretKey + "generateToken";
  // Step 1: XOR each character with the secret key
  for (let i = 0; i < data.length; i++) {
    let dataCharCode = data.charCodeAt(i);
    let keyCharCode = secretKey.charCodeAt(i % keyLength);
    let xorCharCode = dataCharCode ^ keyCharCode;
    encrypted += String.fromCharCode(xorCharCode);
  }

  // Step 2: Simple character permutation (swap characters in pairs)
  let permuted = "";
  for (let j = 0; j < encrypted.length; j += 2) {
    if (j + 1 < encrypted.length) {
      permuted += encrypted[j + 1] + encrypted[j];
    } else {
      permuted += encrypted[j]; // Last character remains if length is odd
    }
  }

  return utf8ToBase64(permuted);
}

export const apiGetPayment = async (url, headers) => {
  var token = generateToken();
  console.log("🚀 ~ apiGetPayment ~ token=>", token);

  headers = {
    ...headers,
    Authorization: "Bearer " + token,
  };
  let instance = axios.create({
    baseURL: BASE_URL,
    headers,
  });
  return await instance.get(url);
};

export const apiPostPayment = async (url, headers, body) => {
  var token = generateToken();
  headers = {
    ...headers,
    Authorization: "Bearer " + token,
  };
  let instance = axios.create({
    baseURL: BASE_URL,
    headers,
  });
  return await instance.post(url, body);
};
