<template>
  <div>
    <h3>Please proceed with the customer payment.</h3>
    <payment-form />
   <payment-time-line />
  </div>
</template>

<script>
import PaymentForm from "@/components/forms/PaymentForm.vue";
import PaymentTimeLine from '@/components/cards/PaymentTimeLine.vue';

export default {
  components: { PaymentForm, PaymentTimeLine },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
