var fetchCustomers = async (state, payload) => {
  state.customers = payload.map((d) => {
    return {
      label: d.name,
      value: d.id,
      ExtraCrdit: d.ExtraCrdit,
    };
  });
};
var fetchCustomerOrders = async (state, payload) => {
  state.orders = payload;
};
var fetchOrderPayment = async (state, payload) => {
  state.payment_history = payload.payment_history;
  state.payment_values = payload.payment_values;
};
var CreatePayment = async (state, payload) => {
  var newState = [...payload.payments, ...state.payment_history];
  state.payment_history = newState;
  state.payment_values = payload.new_values;

};

var addManualValues = async (state , payload) => {

}
export const PaymentMutations = {
  fetchCustomers,
  fetchCustomerOrders,
  fetchOrderPayment,
  CreatePayment,addManualValues
};
