<template>
  <rest-request-from />
</template>

<script>
import { reactive, toRefs } from "vue";
import RestRequestFrom from '@/components/forms/RestRequestFrom.vue';

export default {
  components: { RestRequestFrom },
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
  watch: {
  $route: {
    immediate: true,
    handler() {
      document.title = "Brand Club | Request Rest Password";
    },
  },
},
};
</script>

<style lang="scss" scoped></style>
