<template>
  <div>
    <n-row :gutter="[14]">
      <n-col :span="12">
        <n-button @click="openDebitModalValue">Add manual debit</n-button>
      </n-col>
      <n-col :span="12">
        <n-button @click="openCreditModalValue">Add Extra Credit</n-button>
      </n-col>
      <n-modal
        v-model:show="state.debitModal"
        :mask-closable="false"
        preset="dialog"
        title="Add Customer debit"
        :on-update:show="setModalsFalse"
        class="user-modal"
        id="userModal"
      >
        <n-input-number
          :v-model:value="state.debit"
          placeholder="Filled the amount"
          :show-button="false"
          class="amount_input"
          @update:value="setDebit"
        >
          <template #suffix> EUR </template>
        </n-input-number>
        <n-space justify="end">
          <n-button @click="setModalsFalse">Cancel</n-button>
          <n-button type="primary" @click="addTheAmount('remove')"
            >Remove this Debit</n-button
          >
          <n-button type="primary" @click="addTheAmount('add')"
            >Add Extra Debit</n-button
          >
        </n-space>
      </n-modal>

      <n-modal
        v-model:show="state.creditModal"
        :mask-closable="false"
        preset="dialog"
        title="Add Customer credit"
        :on-update:show="setModalsFalse"
        class="user-modal"
        id="userModal"
      >
        <n-input-number
          :v-model:value="state.credit"
          placeholder="Filled the amount"
          :show-button="false"
          label="Manual customer credit"
          class="amount_input"
          @update:value="setCredit"
        >
          <template #suffix> EUR </template>
        </n-input-number>
        <n-space justify="end">
          <n-button @click="setModalsFalse">Cancel</n-button>
          <n-button type="primary" @click="addTheAmount('remove')"
            >Remove this Credit</n-button
          >
          <n-button type="primary" @click="addTheAmount('add')"
            >Add Extra Credit</n-button
          >
        </n-space>
      </n-modal>
    </n-row>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";

export default {
  props: ["customerId"],
  setup(props) {
    var store = useStore();

    const state = reactive({
      creditModal: false,
      debitModal: false,
      debit: 0,
      credit: 0,
      customerId: computed(() => props.customerId),
    });
    var openCreditModalValue = () => {
      state.creditModal = true;
    };
    var setModalsFalse = () => {
      state.creditModal = false;
      state.debitModal = false;
    };
    var openDebitModalValue = () => {
      state.debitModal = true;
    };
    var setDebit = (value) => {
      state.debit = value;
    };
    var setCredit = (value) => (state.credit = value);
    var addTheAmount = async (e) => {
      var payload = {};
      payload.credit = e == "add" ? state.credit : -state.credit;
      payload.debit = e == "add" ? state.debit : -state.debit;
      payload.customer_id = state.customerId;

      var result = await store.dispatch("addManualValues", payload);
      if (result) {
        setModalsFalse();
        state.credit = 0;
        state.debit = 0;
      }
    };
    return {
      openDebitModalValue,
      openCreditModalValue,
      state,
      setModalsFalse,
      addTheAmount,
      setDebit,
      setCredit,
    };
  },
};
</script>

<style>
.amount_input {
  margin: 35px 0;
}
</style>
