<template>
  <n-result
    status="403"
    title="403 Forbidden"
    description="Some of the doors are sometimes closed."
  >
    <template #footer>
      <n-button>Go Back</n-button>
    </template>
  </n-result>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped></style>
