import http from "@/services/http";

const Me = async (context) => {
  var { data, error } = await http.Me();
  if (error || !data) {
    context.commit("setLoading", false);
    return false;
  }
  context.commit("sendLoggedIn", data.user);
  return data.user;
};
const Login = async (context, payload) => {
  context.commit("setLoading", true);
  console.log(payload);
  var { data, error } = await http.LoginCall(payload);
  if (error || !data) {
    var message = error || "Please verify your request !";
    context.commit("sendErrorMessage", message);
    return false;
  } else {
    context.commit("sendLoggedIn", data.user);
    localStorage.setItem("auth-token", data.token);
    localStorage.setItem("permissions", data.user.permissions);
    return true;
  }
};
const logout = async (context) => {
  context.commit("logout");
};
var fetchNotifications = async (context) => {
  var { data, error } = await http.fetchNotifications();
  console.log('this is data',data)
  console.log('and this is amount',data.notifications_amount)
  if (error || !data) {
    return false;
  } else {
    context.commit("fetchNotifications", data.notifications);
    context.commit('fetchAmount',data.notifications_amount);
    return true;
  }
};
var MarkAsSeen = async (context) => {
  var {result, error} = await http.MarkAsSeen();
  if(error) {
    context.commit('sendErrorMessage',error);
    return false;
  }
  context.commit('MarkAsSeen',result);
  return true;
}
var requestRestPassowrd = async (context, payload) => {
  var { data, error } = await http.requestRestPassword(payload);
  if (error || !data) {
    context.commit("sendErrorMessage", error.message);

    return false;
  }
  context.commit("sendMessage", data.message);
  return true;
};
var ChangePassword= async (context , payload) => {
  var { data, error } = await http.requestRestPasswordUpdate(payload);
  if (error || !data) {
    context.commit("sendErrorMessage", error);

    return false;
  }
  context.commit("sendMessage", data.message);
  return true;
}
export const authActions = {
  Me,
  Login,
  logout,
  fetchNotifications,
  
  requestRestPassowrd,ChangePassword,MarkAsSeen
};
