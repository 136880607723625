<template>
  <n-result
    status="404"
    title="404 Not Found"
    description="You know we can't find everything."
  >
    <template #footer>
      <n-button>Find Something else</n-button>
    </template>
  </n-result>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped></style>
