<template>
  <n-card class="welcome_card">
    <img :src="BirthdayImage" class="birthday_image" />
    <n-h1 class="welcome_text">
      Happy Birthday
      <b class="username"
        >{{ state?.user?.firstName }} {{ state?.user?.lastName }}</b
      ></n-h1
    >
    <n-h5 class="message">{{ message.text }}</n-h5>
    <p class="hashtag">{{ message.tag }}</p>
  </n-card>
</template>

<script>
import { computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import BirthdayImage from "@/assets/birthday_image.png";
var messages = [
  {
    text: "On your special day, may all your dreams take flight and your heart be filled with happiness. Happy Birthday!",
    tag: "#BirthdayDreamsComeTrue 🌟",
  },
  {
    text: "Wishing you a year of exciting adventures, beautiful moments, and endless smiles. Happy Birthday!",
    tag: "#BirthdayAdventureAwaits 🤝",
  },
  {
    text: "May this birthday be the beginning of the best year yet. Happy Birthday!",
    tag: "#BestBirthdayEver 💼🚀",
  },
  {
    text: "May your birthday be as sweet as cake and as bright as candles. Happy Birthday!",
    tag: "#SweetBirthdayCelebration 🎉",
  },
  {
    text: "Cheers to you and another year of making wonderful memories. Happy Birthday!",
    tag: "#MemorableBirthdayMoments ❤️👥",
  },
  {
    text: "Sending you warm birthday wishes and a big hug on your special day. Happy Birthday!",
    tag: "#WarmBirthdayHugs 💼🌟",
  },
  {
    text: "May your birthday be filled with laughter, love, and the company of those you cherish. Happy Birthday!",
    tag: "#BirthdayLoveAndLaughter 🚀",
  },
  {
    text: "Here's to celebrating you and the amazing person you are. Happy Birthday!",
    tag: "#AmazingBirthdayPerson 🏆🎯",
  },
];

export default {
  setup() {
    var store = useStore();
    const state = reactive({
      user: computed(() => store.state.user),
    });

    var randomIndex = Math.floor(Math.random() * messages.length);
    return {
      state,
      BirthdayImage,
      message: messages[randomIndex],
    };
  },
};
</script>

<style>
.welcome_card {
  margin: 35px 0 0 0;
  width: 100% !important;
}
.welcome_card > .n-card__content:first-child,
.n-card > .n-card__footer:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
  flex-direction: column;
  z-index: 1000;
}
.birthday_image {
  position: absolute;
  top: -136px;
  width: 550px;
  height: 220px;
}
.welcome_text {
  text-align: center;
  align-self: center;
}
.username {
  color: green;
}
.message {
  margin: 0;
}

.hashtag {
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff95; /* Blue color for the hashtag background */
  padding: 2px 3px;
  border-radius: 4px;
  text-transform: uppercase;
  display: inline-block;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
