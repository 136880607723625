<template>
    <div class="login_container">
      <n-card size="small" class="login_card">
        <n-h3> Request a rest password link </n-h3>
  
        <n-form ref="formRef" :model="state" :rules="rules">
          <n-form-item path="email" label="Email">
            <n-input
              v-model:value="state.email"
              @keydown.enter.prevent
              placeholder="example@brandclub.lt"
            />
          </n-form-item>
          <n-button
            :disabled="state.email == null || state.email.length == 0 || state.loading == true"
            round
            class="login_button"
            type="primary"
            :loading="state.loading"
            @click="SubmitRequest"
          >
            Send request
          </n-button>
        </n-form>
      </n-card>
    </div>
  </template>
  
  <script>
  import { reactive, ref } from "vue";
  import { useStore } from "vuex";
  
  export default {
    setup() {
      var store = useStore();
      var rules = {
        email: [  
          {
            required: true,
            validator(rule, value) {
              const emailRegex =
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              console.log(emailRegex.test(value));
              if (!value) {
                return false;
              } else if (!emailRegex.test(value)) {
                return false;
              }
              // else if (!value.includes("brandclub.lt")) {
              //   return false;
              // }
              return true;
            },
            message: "verify your email !",
          },
        ],
      };
      const state = reactive({
        email: null,
        loading : false
      });
      const formRef = ref(null);
      var SubmitRequest = async () => {
        try {
            state.loading = true
          await formRef.value.validate(async (errors) => {
            if (errors) {
              console.log(errors);
            } else {
              await store.dispatch("requestRestPassowrd", state).then(() => {
                state.loading = false
              });
              state.email = null;
            }

          });
        } catch (error) {
          console.log(error);
        }
      

      };
      return {
        state,
        rules,
        SubmitRequest,
        formRef,
      };
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  