<template>
  <n-card title="" class="ticket_container">
    <n-collapse>
      <n-collapse-item title="Ticket tracking" name="1">
        <iframe
          src="https://sharing.clickup.com/43245772/gr/h/197r6c-2352/0a633dad02fd3e2"
          class="tracking-frame"
        ></iframe
      ></n-collapse-item>
    </n-collapse>
  </n-card>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style>
.ticket_container {
  width: 100% !important;
}
.tracking-frame {
  border: none;
  width: 100%;
  height: 285px;
}
</style>
