import { createStore } from "vuex";
import { state } from "./states";
import { mutations } from "./modules";
import { actions } from "./modules";
const store = createStore({
  state,
  mutations,
  actions,
});
export default store;
