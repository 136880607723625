var sendErrorMessage = async (state, payload) => {
  state.error_message = payload;
  state.hasError = true;
  state.loading = false;
};
var sendMessage = async (state, payload) => {
  state.message = payload;
  state.hasMessage = true;
  state.loading = false;
};

var emptyMessages = async (state) => {
  state.error_message = null;
  state.hasError = false;
  state.message = null;
  state.hasMessage = false;
};
var changeTheme = async (state, payload) => {
  state.themeMode = payload;
};
export const MessageMutations = {
  sendErrorMessage,
  emptyMessages,
  changeTheme,
  sendMessage,
};
