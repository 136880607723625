<template>
  <img :src=" state.themeMode === 'dark' ?  require('./../assets/whiteLogo.png') : require('./../assets/bclogo.png')" class="logo" />
</template>

<script>
import { brandclub_logo } from "@/assets/BcLog";
import { computed, reactive } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const state = reactive({
      themeMode : computed(()=>store.state.themeMode)
    })
    return {
      brandclub_logo,
      state
    };
  },
  
  
};
</script>

<style>
.logo {
  width: 100px;
  height: 40px;
}
.darkLogo {
  width: 100px;
  height: 42px;
}
</style>
