<template>
  <n-modal
    v-model:show="state.showReplyModal"
    :mask-closable="false"
    preset="dialog"
    title="Respond to the Leave Request"
    :on-update:show="upateShow"
    class="reply-modal"
    id="reply_modal"
  >
    <accept-reply v-if="state.reply_action == 'accept'" />
    <refuse-reply v-if="state.reply_action == 'refuse'" />
  </n-modal>
</template>

<script>
import { computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import AcceptReply from "../forms/AcceptReply.vue";
import RefuseReply from "../forms/RefuseReply.vue";

export default {
  components: { RefuseReply, AcceptReply },
  setup() {
    var store = useStore();
    var upateShow = () => {
      store.dispatch("showReply", { status: false, _id: null, action: null });
    };
    const state = reactive({
      showReplyModal: computed(() => store.state.showReplyModal),
      reply_to: computed(() => store.state.reply_to),
      reply_action: computed(() => store.state.reply_action),
    });

    return {
      upateShow,
      state,
    };
  },
};
</script>

<style>
.reply-modal {
  width: 600px !important;
}
</style>
