<template>
  <div class="login_container">
    <n-card size="small" class="login_card">
      <n-h3> Create a new password </n-h3>

      <n-form ref="formRef" :model="state" :rules="rules">
        <n-form-item path="password" label="Password">
          <n-input
            v-model:value="state.password"
            @keydown.enter.prevent
            type="password"
            name="new-password"
            show-password-on="click"
            placeholder="insert your new password"
            :minlength="12"
            :status="state.status"
          />
        </n-form-item>
        <n-button
          :disabled="state.password == null || state.password.length == 0"
          round
          class="login_button"
          type="primary"
          @click="SubmitRequest"
        >
          Change password
        </n-button>
      </n-form>
    </n-card>
  </div>
</template>

<script>
import { reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    var store = useStore();
    var rules = {};
    const state = reactive({
      password: null,
      status: null,
    });
    const formRef = ref(null);
    var router = useRouter();
    var route = useRoute();
    function isValidPassword(password) {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{12,}$/;
      return pattern.test(password);
    }
    watch(() => {
      if (!state.password || state.password?.length == 0) {
        state.status = null;
        return;
      }
      if (isValidPassword(state.password)) {
        state.status = "success";
      } else {
        state.status = "error";
      }
    });
    var SubmitRequest = async () => {
      console.log(route.params.token);
      try {
        await formRef.value.validate(async (errors) => {
          if (errors) {
            console.log(errors);
          } else {
            console.log(route);
            await store.dispatch("ChangePassword", {
              resetToken: route.params.token,
              newPassword: state.password,
            });
            state.password = null;
            router.push("/login");
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    return {
      state,
      rules,
      SubmitRequest,
      formRef,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "Brand Club | Update Password";
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
