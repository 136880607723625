import { authActions } from "./Auth/actions";
import { AuthMutations } from "./Auth/mutations";
import { MessageMutations } from "./messages/mutations";
import { messageActions } from "./messages/actions";
import { loadingMutations } from "./Loading/mutations";
import { loadingActions } from "./Loading/actions";
import { UsersMutations } from "./users/mutations";
import { UsersActions } from "./users/actions";
import { HolidaysActions } from "./holidays/actions";
import { MutationHolidays } from "./holidays/mutations";
import { EventActions } from "./Events/actions";
import { EventMutations } from "./Events/mutations";
import { PaymentActions } from "./payment/actions";
import { PaymentMutations } from "./payment/mutations";
export const mutations = {
  ...AuthMutations,
  ...MessageMutations,
  ...loadingMutations,
  ...UsersMutations,
  ...MutationHolidays,
  ...EventMutations,
  ...PaymentMutations,
};

export const actions = {
  ...authActions,
  ...messageActions,
  ...loadingActions,
  ...UsersActions,
  ...HolidaysActions,
  ...EventActions,
  ...PaymentActions,
};
