import http from "@/services/http";

var fetchCustomers = async (context, payload) => {
  var { data, error } = await http.fetchCustomersAPI();
  if (error || !data) {
    return false;
  }
  context.commit("fetchCustomers", data);

  return true;
};

var fetchCustomerOrders = async (context, payload) => {
  console.log(payload);
  var { data, error } = await http.fetchCustomersOrdersAPI(payload);
  if (error || !data) {
    console.log(error);
    context.commit("sendErrorMessage", error);

    return false;
  }
  context.commit("fetchCustomerOrders", data);

  return true;
};

var fetchOrderPayment = async (context, payload) => {
  var { data, error } = await http.fetchOrderPaymentAPI(payload);
  if (error || !data) {
    console.log(error);
    context.commit("sendErrorMessage", error);
    return false;
  }
  context.commit("fetchOrderPayment", data);

  return true;
};

var CreatePayment = async (context, payload) => {
  var { data, error } = await http.createPaymentAPI(payload);
  if (error || !data) {
    console.log(error);
    context.commit("sendErrorMessage", error);

    return false;
  }
  context.commit("CreatePayment", data);
  context.commit("sendMessage", "Payment created successfully!");

  return true;
};

var addManualValues = async (context, payload) => {
  try {
    var { data, error } = await http.addManualPaymentValueAPI(payload);
    if (!data || error) {
      context.commit("sendErrorMessage", error);
      return false;
    }
    context.commit("sendMessage", "Amount has been added successfully!");
    context.commit("addManualValues",data);

    return true;
  } catch (error) {
    return false;
  }
};

export const PaymentActions = {
  fetchCustomers,
  fetchCustomerOrders,
  fetchOrderPayment,
  CreatePayment,
  addManualValues,addManualValues
};
