import * as VueRouter from "vue-router";
import HomeVue from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Users from "@/views/Users.vue";
import Forbidden from "@/views/403.vue";
import NotFound from "@/views/404.vue";
import StuffLeave from "@/views/StuffLeave.vue";
import Calander from "@/views/Calander.vue";
import Services from "@/views/Services.vue";
import EmployeeRequests from "@/views/EmployeeRequests.vue";
import RequestRest from "@/views/RequestRest.vue";
import UpdatePasswordRequest from "@/views/UpdatePasswordRequest.vue";
import Payment from "./views/Payment.vue";
const routes = [
  {
    path: "/",
    component: HomeVue,
    name: "home",
    alias: ["/home", "/dashboard"],
  },
  // routers path identic to /dashboard/menuOptionsAdmin-employee
  { path: "/login", component: Login, name: "login" },
  { path: "/request-rest", component: RequestRest, name: "request-rest" },
  { path: "/users", component: Users, name: "users" },
  { path: "/403-forbidden", component: Forbidden, name: "Forbidden" },
  { path: "/staff-leaves", component: StuffLeave, name: "staff-leave" },
  { path: "/Calendar", component: Calander, name: "Calendar" },
  { path: "/services", component: Services, name: "services" },
  { path: "/my-holidays", component: EmployeeRequests, name: "my-holidays" },
  { path: "/customer-payment", component: Payment, name: "customer-payment" },
  {
    path: "/reset/:token",
    component: UpdatePasswordRequest,
    name: "update-rest",
    props: true,
  },
  { path: "/:catchAll(.*)", component: NotFound, name: "not-found" },
];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
  mode: "history",
});

export default router;
