<template>
  <n-form ref="formRef" class="user_form" :model="formData" :rules="rules">
    <n-space justify="space-between">
      <n-form-item label="First Name" path="firstName">
        <n-input v-model:value="formData.firstName" />
      </n-form-item>
      <n-form-item label="Last Name" path="lastName">
        <n-input v-model:value="formData.lastName"></n-input>
      </n-form-item>
    </n-space>
    <n-space :wrap="false" justify="space-between">
      <n-form-item label="Personal Email" path="personalEmail">
        <n-input v-model:value="formData.personalEmail" type="email"></n-input>
      </n-form-item>
      <n-form-item label="Password" path="password">
        <n-input v-model:value="formData.password" type="password"></n-input>
      </n-form-item>
    </n-space>
    <n-space :wrap="false" justify="space-between">
      <n-form-item label="Position" path="job">
        <n-select
          filterable
          tag
          v-model:value="formData.job"
          :options="state.mergedOptions"
        ></n-select>
      </n-form-item>
      <n-form-item label="Role" path="role">
        <n-select
          filterable
          v-model:value="formData.role"
          :options="roleOptions"
        >
        </n-select>
      </n-form-item>
    </n-space>
    <n-space class="dateSpace" justify="space-between">
      <div>
        <n-form-item label="Start Date" path="startDate">
          <n-date-picker
            placeholder=" "
            v-model:value="formData.startDate"
            type="date"
          ></n-date-picker>
        </n-form-item>
      </div>
      <n-form-item label="Birthday" path="birthday">
        <n-date-picker
          placeholder=" "
          v-model:value="formData.birthday"
          type="date"
        ></n-date-picker>
      </n-form-item>
    </n-space>
    <n-space justify="space-between">
      <div>
        <n-form-item label="Credentials" path="credentials">
          <n-input v-model:value="formData.credentials" type="text"></n-input>
        </n-form-item>
      </div>
      <n-form-item label="Unique Number" path="uniqueNumber">
        <n-input v-model:value="formData.uniqueNumber" type="text"></n-input>
      </n-form-item>
    </n-space>
    <n-form-item label="Permissions" path="permissions">
      <n-select
        filterable
        v-model:value="formData.permissions"
        :options="userPermissions"
        multiple
      ></n-select>
    </n-form-item>
    <n-form-item label="Internal Services" path="internalServices">
      <n-select
        filterable
        v-model:value="formData.internalServices"
        :options="InternalServices"
        multiple
      ></n-select>
    </n-form-item>
    <n-form-item label="External Services" path="externalServices">
      <n-select
        filterable
        v-model:value="formData.externalServices"
        :options="externalServices"
        multiple
      ></n-select>
    </n-form-item>
    <n-form-item label="Children Birthdays" path="childrenBirthdays">
      <n-dynamic-tags v-model:value="formData.childrenBirthdays">
        <template #input="{ submit, deactivate }">
          <n-date-picker
            type="date"
            v-model:value="inputValue"
            size="small"
            :clear-after-select="true"
            @change="submitChild(submit)"
            @blur="deactivate"
          />
        </template>
      </n-dynamic-tags>
    </n-form-item>
    <n-space justify="space-between">
      <n-form-item> <n-button @click="cancel">Cancel</n-button> </n-form-item>
      <n-form-item>
        <n-button type="primary" @click="submitForm">Submit</n-button>
      </n-form-item>
    </n-space>
  </n-form>
</template>
<script>
import { initialJobOptions } from "@/data/TableColumns";
import moment from "moment";
import { ref, computed, watch, nextTick, reactive, onMounted } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    function capitalizeFirstLetter(str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    }
    var store = useStore();
    var state = reactive({
      Positions: computed(() => store.state.Positions),
      mergedOptions: [],
    });
    onMounted(() => {
      state.mergedOptions = initialJobOptions.concat(
        state.Positions?.map((position) => ({
          label: capitalizeFirstLetter(position),
          value: position,
        }))
      );
    });
    var userPermissions = ref([
      { label: "Can register products", value: "Can register products" },
      { label: "Can write descriptions", value: "Can write descriptions" },
      { label: "Can view KPI dashboard", value: "Can view KPI dashboard" },
      { label: "Can manage supply", value: "Can manage supply" },
      { label: "Can manage configuration", value: "Can manage configuration" },
      { label: "Can manage users", value: "Can manage users" },
      { label: "Flash sellings", value: "Flash selling" },
      { label: "Can view listings", value: "Can view listings" },
    ]);
    const roleOptions = ref([
      { label: "Admin", value: "admin" },
      { label: "Employee", value: "employee" },
    ]);
    var rules = {
      lastName: {
        required: true,
        message: "this field is required !",
        trigger: ["input", "blur"],
      },
      firstName: {
        required: true,
        message: "this field is required !",
        trigger: ["input", "blur"],
      },
      job: {
        required: true,
        message: "this field is required !",
        trigger: ["input", "blur"],
      },
      role: {
        required: true,
        message: "this field is required !",
        trigger: ["input", "blur"],
      },
      personalEmail: {
        required: true,
        message: "this field is required !",
        trigger: ["blur", "input"],
        validator: ValidateEmail,
      },
      password: {
        required: true,
        message: "this field is required !",
        trigger: ["blur", "input"],
        validator: ValidatePassword,
      },
      uniqueNumber: {
        required: true,
        message: "this field is required !",
        trigger: ["blur", "input"],
      },
      credentials: {
        required: true,
        message: "this field is required !",
        trigger: ["blur", "input"],
      },
    };
    var externalServices = [
      { label: "eBay", value: "eBay" },
      { label: "Linnworks", value: "Linnworks" },
      { label: "Merkandi UK", value: "Merkandi UK" },
      { label: "ParcelStars", value: "ParcelStars" },
      { label: "Etsy", value: "Etsy" },
      { label: "Allegro", value: "Allegro" },
      { label: "Pigu.lt", value: "Pigu.lt" },
      { label: "Amazon Seller", value: "Amazon Seller" },
    ];
    var InternalServices = [
      { label: "ProductsDB", value: "ProductsDB" },
      { label: "PS Connector", value: "PS Connector" },
      { label: "Brandclub Price List", value: "Brandclub Price List" },
      { label: "Price List", value: "Price List" },
      { label: "Brandclub.lt", value: "Brandclub.lt" },
      { label: "BC Function", value: "BC Function" }
    ];
    var ValidateRequired = (value) => {
      if (!value || value.length < 1) return false;
      else return true;
    };
    var ValidateEmail = (rule, value) => {
      const emailRegex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      console.log("this is value =>", value);
      return emailRegex.test(value);
    };
    var ValidatePassword = (value) => {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{12,}$/;
      if (passwordRegex.test(value)) return true;
      else return false;
    };
    const formRef = ref(null);
    const inputValueRef = ref(new Date());

    const formData = reactive({
      firstName: null,
      lastName: null,
      job: null,
      role: null,
      startDate: new Date().getTime(),
      birthday: new Date().getTime(),
      personalEmail: null,
      password: null,
      credentials: null,
      uniqueNumber: null,
      permissions: [],
      email: null,
      externalServices: [],
      InternalServices: [],
      childrenBirthdays: [],
    });
    console.log("children Birthdays", formData.childrenBirthdays);
    var cancel = () => {
      store.dispatch("modalStatus", false);
    };
    var submitForm = async (e) => {
      e.preventDefault();
      formRef.value
        ?.validate()
        .then(async (res) => {
          formData.birthday = new Date(formData.birthday);
          formData.startDate = new Date(formData.startDate);
          formData.email = formData.personalEmail;

          var result = await store.dispatch("addUser", formData);
          console.log(result);
          if (result) {
            cancel();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    var submitChild = async (submitFunc) => {
      formData.childrenBirthdays.push(
        moment(inputValueRef.value).format("DD/MM/YYYY")
      );
      submitFunc();
    };
    return {
      submitChild,
      formData,
      roleOptions,
      cancel,
      submitForm,
      rules,
      formRef,
      userPermissions,
      InternalServices,
      externalServices,
      inputValue: inputValueRef,
      state,
    };
  },
};
</script>
<style>
.user_form {
  margin-top: 3em;
}
</style>
