<template>
  <div>
    <welcome v-if="state.user && !state.user.isMyBirthday"></welcome>
    <happy-birthday
      v-if="state.user && state.user.isMyBirthday"
    ></happy-birthday>
    <notifications></notifications>
    <n-space
      wrap-item="false"
      item-style="width : 100%"
      :wrap="false"
      size="large"
      justify="center"
      align-items="center"
      class="middleItems"
    >
      

      <ticket-track />
    </n-space>
    <div style="width: 100%">
      <all-services></all-services>
    </div>
  </div>
</template>
<script>
import { computed, reactive } from "vue";
import Welcome from "@/components/cards/Welcome.vue";
import HappyBirthday from "@/components/cards/HappyBirthday.vue";
import Notifications from "@/components/forms/Notifications.vue";
import { useStore } from "vuex";
import AllServices from "@/components/cards/AllServices.vue";
import TicketTrack from "@/components/cards/TicketTrack.vue";

export default {
  components: {
    Welcome,
    HappyBirthday,
    Notifications,
    AllServices,
    TicketTrack,
  },
  setup() {
    var store = useStore();
    const state = reactive({
      isMyBirthday: true,
      user: computed(() => store.state.user),
    });

    return {
      state,
    };
  },
};
</script>

<style>
.middleItems {
  margin: 1em 0;
}
</style>
