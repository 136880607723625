<template>
  <!-- <div class="notification-bell" @click="toggleNotifications">
      <Person style="width: 25px; height: 25px;" />
      <span class="badge" v-if="notifications.length > 0">{{ notificationsAmount }}</span>
     
    </div> -->
  <n-popover
    :on-update:show="toggleNotifications"
    :overlap="overlap"
    placement="bottom"
    trigger="hover"
  >
    <template #trigger>
      <n-badge :value="notificationsAmount" :max="10">
        <NotificationsSharp
          style="
            width: 20px;
            height: 20px;
            cursor: pointer;
            color: grey !important;
          "
        />
      </n-badge>
    </template>

    <div class="notification-list">
      <div v-for="(notification, index) in notifications" :key="index">
        <n-badge
          style="width: 100%"
          :value="0"
          :dot="!notification.seen"
          type="info"
          :show-zero="!notification.seen"
          :v-show="!notification.seen"
        >
          <n-card style="padding: 0.1em !important ; margin-top: 10px">
            <p
              @click="switchViews(notification)"
              style="margin: 0; padding: 0; font-size: 12px; cursor: pointer"
            >
              {{ notification.description }}
            </p>
            <span style="font-size: 10px; color: grey">{{
              notification.date
            }}</span>
          </n-card>
        </n-badge>
      </div>

      <n-empty
        class="small-notification-list"
        v-if="notifications.length === 0"
        description="There is no notifications"
      >
      </n-empty>
    </div>
  </n-popover>
</template>

<script>
import { Person, NotificationsSharp } from "@vicons/ionicons5";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  components: {
    Person,
    NotificationsSharp,
  },
  setup() {
    const store = useStore();
    const showNotifications = ref(false);

    onMounted(async () => {
      await store.dispatch("fetchNotifications");
    });
    const notifications = computed(() => store?.state?.notifications || []);
    const notificationsAmount = computed(
      () => store?.state?.notificationAmount || 0
    );
    const MarkAsSeen = computed(() => store.state.MarkAsSeen);
    const toggleNotifications = async () => {
      await store.dispatch("fetchNotifications");
      showNotifications.value = !showNotifications.value;
      console.log("before", MarkAsSeen);
      await store.dispatch("MarkAsSeen");
      console.log("after", MarkAsSeen);
    };
    const router = useRouter();
    const switchViews = (notification) => {
      if (notification.type == "request_leave") {
        router.push("/staff-leaves");
        return;
      }
      if (notification.type == "reply_leave") {
        router.push("/my-holidays");
        return;
      }
      if (
        notification.type == "birthday" ||
        notification.type == "anniversary" ||
        notification.type == "holidays"
      ) {
        router.push("/Calendar");
        return;
      }
    };
    return {
      showNotifications,
      notifications,
      notificationsAmount,
      toggleNotifications,
      switchViews,
    };
  },
};
</script>

<style>
.notification-list .n-badge .n-badge-sup {
  left: 1em !important;
  top: 1.5em !important;
}
.notification-list {
  height: 290px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

</style>
