import http from "@/services/http";
import moment from "moment";

var createCustomEvent = async (context, payload) => {
  payload.startDate = new Date(payload.date[0])
  payload.endDate = new Date(payload.date[1])
  var { data, error } = await http.createCustomEventAPI(payload);
  if (error || !data) {
    context.commit("sendErrorMessage", error);

    return false;
  }
  context.commit("createCustomEvent", data.event);

  context.commit("sendMessage", data.message);
  return true;
};

var UpdateCustomEvent = async (context, payload) => {
  payload.startDate = new Date(payload.date[0])
  payload.endDate = new Date(payload.date[1])
  var { data, error } = await http.UpdateCustomEventAPI(payload);
  if (error || !data) {
    context.commit("sendErrorMessage", error);

    return false;
  }
  context.commit("UpdateCustomEvent", data.event);

  context.commit("sendMessage", data.message);
  return true;
};

var DeleteCustomEvent = async (context, payload) => {

  var { data, error } = await http.DeleteCustomEventAPI(payload);
  if (error || !data) {
    context.commit("sendErrorMessage", error);

    return false;
  }
  context.commit("DeleteCustomEvent", payload);

  context.commit("sendMessage", data.message);
  return true;
};

export const EventActions = {
  createCustomEvent,UpdateCustomEvent, DeleteCustomEvent
};
