var fetchHolidays = async (state, payload) => {
  state.holidays = payload;
};
var fetchCalander = async (state, payload) => {
  state.calander = payload;
};
var fetchMyLeaves = async (state, payload) => {
  state.myLeaves = payload;
};
var showReply = async (state, payload) => {
  state.showReplyModal = payload.status;
  state.reply_to = payload._id;
  state.reply_action = payload.action;
  var currentHoliday = state.holidays.find((h) => h._id === payload._id);
  state.currentHoliday = currentHoliday;
};
var requestHoliday = async (state, payload) => {
  state.holidays = [payload, ...state.holidays];
};
var replyHoliday = async (state, payload) => {
  console.log(payload);
  state.holidays = state.holidays.map((item) => {
    if (item._id == payload._id) return payload;
    else return item;
  });
};

var RemoveHolidays = (state, payload) => {
  state.holidays = [];
};
var updateLeave = (state, payload) => {
  state.holidays = state.holidays.map((item) => {
    if (item._id == payload._id) return payload;
    else return item;
  });
};
var fetchToday = (state,payload) => {
  state.today = payload;
}
var calendarShowMore = (state,payload) => {
  state.calendarShowMore = payload;
}
var setHolidayConflict = async (state, payload) => {
  console.log(payload);
  state.holidaysConflict = payload;
  state.hasHolidayConflict = payload.length > 0;
};
export const MutationHolidays = {
  fetchHolidays,
  showReply,
  requestHoliday,
  replyHoliday,
  RemoveHolidays,
  updateLeave,
  fetchMyLeaves,
  fetchCalander,
  fetchToday,
  calendarShowMore,
  setHolidayConflict,
};
