<template>
  <a target="_blank" class="service_link" :href="props.link">
    <n-card ::title="props.title" class="service_card">
      <img :src="require(`@/assets/${props.logo}`)" class="service_logo" />
      <n-p class="service_title">{{ props.title }}</n-p>
    </n-card>
  </a>
</template>

<script>
export default {
  props: ["logo", "title", "link"],
  setup(props) {
    return {
      props,
    };
  },
};
</script>

<style>
.service_logo {
  width: 90px;
}
.service_card {
  height: 170px;
}
.service_card > .n-card__content:first-child,
.n-card > .n-card__footer:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 170px;

}
.service_link  {
  text-decoration: none;
}
.service_title {
  margin: 0;
  font-weight: 600;
}
</style>
