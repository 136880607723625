<template>
  <n-card class="welcome_card">
    <img :src="welcomeImage" class="welcome_image" />
    <n-h1 class="welcome_text">
      Welcome Back
      <b class="username"
        >{{ state?.user?.firstName }} {{ state?.user?.lastName }}</b
      ></n-h1
    >
    <n-h5 class="message">{{ message.text }}</n-h5>
    <p class="hashtag">{{ message.tag }}</p>
  </n-card>
</template>

<script>
import { computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import welcomeImage from "@/assets/welcome_image.png";
var messages = [
  {
    text: "Join us as we welcome fresh talent to our vibrant team!",
    tag: "#NewFacesNewBeginnings 🌟",
  },
  {
    text: "Embrace collaboration and let's reach new heights!",
    tag: "#TogetherWeGrow 🤝",
  },
  {
    text: "Uniting strengths for success, one project at a time.",
    tag: "#TeamworkMakesDreamsWork 💼🚀",
  },
  {
    text: "Happy to have you back! Let's make the most of every opportunity.",
    tag: "#ReunitedAndThriving 🎉",
  },
  {
    text: "A warm welcome to our newest family member!",
    tag: "#OneTeamOneFamily ❤️👥",
  },
  {
    text: "Your journey to greatness starts here.",
    tag: "#EmpoweringSuccess 💼🌟",
  },
  {
    text: "Join the pioneers shaping the future of our company.",
    tag: "#InnovateWithUs 🚀",
  },
  {
    text: "Welcome aboard, let's accomplish the extraordinary.",
    tag: "#TogetherWeAchieve 🏆🎯",
  },
  {
    text: "Discover new heights of excellence in our team.",
    tag: "#UnleashYourPotential 🌟💼",
  },
  {
    text: "We believe in your abilities to excel! Welcome to the team!",
    tag: "#EmbraceTheChallenge 💪🔥",
  },
  {
    text: "Thrilled to see you back in action, ready to conquer new challenges!",
    tag: "#WelcomeBackRockstar 🌟",
  },
  {
    text: "Your presence enriches our team. Let's achieve greater heights together!",
    tag: "#ContinuedSuccess 🚀",
  },
  {
    text: "We missed your valuable contributions. Welcome back to the team!",
    tag: "#BackInTheGame 💼🎉",
  },
  {
    text: "Reunited and stronger than ever. Welcome back!",
    tag: "#TogetherAgain 👥",
  },
  {
    text: "Your return adds another layer of awesomeness to our collaborative spirit!",
    tag: "#TeamworkMatters 🤝",
  },
  {
    text: "Get ready for more victories as we embark on this journey together!",
    tag: "#WelcomeBackChamp 🏆🌟",
  },
  {
    text: "We're delighted to have you back, making our team whole again!",
    tag: "#HappyReturns 🎊",
  },
  {
    text: "Welcome back! Let's revitalize and achieve success like never before!",
    tag: "#RejoinReviveThrive 💼💪",
  },
  {
    text: "Your seat at the table was never empty. Welcome back to the heart of our team!",
    tag: "#WelcomeHome 🏡",
  },
  {
    text: "Your skills and experience are vital, and we're excited to see what we'll accomplish together!",
    tag: "#TogetherWePersist 🚀🌈",
  },
];

export default {
  setup() {
    var store = useStore();
    const state = reactive({
      user: computed(() => store.state.user),
    });

    var randomIndex = Math.floor(Math.random() * messages.length);
    return {
      state,
      welcomeImage,
      message: messages[randomIndex],
    };
  },
};
</script>

<style>
.welcome_card {
  margin: 120px 0;
}
.welcome_card > .n-card__content:first-child,
.n-card > .n-card__footer:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
  flex-direction: column;
  z-index: 1000;
}
.welcome_image {
  position: absolute;
  top: -95px;
  width: 550px;
  height: 180px;
}
.welcome_text {
  text-align: center;
  align-self: center;
}
.username {
  color: green;
}
.message {
  margin: 0;
}

.hashtag {
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007bff95; /* Blue color for the hashtag background */
  padding: 2px 3px;
  border-radius: 4px;
  text-transform: uppercase;
  display: inline-block;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
