<template>
  <div>
    <n-data-table
      ref="dataTableInst"
      :columns="columns"
      :data="data"
      :pagination="pagination"
      size="small"
      max-height="56vh"
      class="data-table-content"
      :loading="state.loading"
    />
  </div>
</template>

<script>
import { computed, reactive, ref, toRefs, h } from "vue";
import { useStore } from "vuex";

export default {
  props: ["rows", "columns", "handleCheck", "loading"],

  setup(props) {
    var store = useStore();
    const state = reactive({
      loading: computed(() => store.state.loading),
    });
    const dataTableInstRef = ref(null);
    var recordPerPage = 25;
    return {
      state,
      props,
      data: computed(() =>
        props.rows
          .slice()
          .reverse()
          .map((i) => {
            return {
              ...i,
              key: i._id,
            };
          })
      ),
      columns: props.columns,
      dataTableInst: dataTableInstRef,
      pagination: ref({
        pageSize: recordPerPage,
        prefix({ itemCount }) {
          return `Max records in page ${recordPerPage} | Total ${itemCount}`;
        },
      }),
    };
  },
};
</script>

<style lang="scss" scoped></style>
