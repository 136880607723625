<template>
  <div>
    <n-button
      strong
      secondary
      size="small"
      style="padding: 1.15em 1.5em !important"
      @click="upateShow"
      type="primary"
    >
      Request a holiday
    </n-button>
    <n-modal
      v-model:show="state.showModal"
      :mask-closable="false"
      preset="dialog"
      title="Submit a Holiday Request"
      :on-update:show="upateShow"
      class="user-modal"
      id="userModal"
    >
      <n-form
        ref="formRef"
        class="user_form"
        :model="formData"
        :rules="state.extraRules ? Extrarules : rules"
      >
        <n-flex
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1.5em;
          "
          :vertical="false"
          justify="center"
        >
          <n-form-item label="Holiday dates" path="date">
            <n-date-picker
              v-if="
                state?.user?.parents_days_off > 0 &&
                formData.type == 'parenting leave'
              "
              type="datetimerange"
              format="yyyy/MM/dd - HH"
              clearable
              :is-date-disabled="isDateDisabled"
              v-model:value="formData.date"
              :is-time-disabled="isDisabled"
              @confirm="onConfirm"
            />
            <n-date-picker
              v-else
              type="daterange"
              clearable
              v-model:value="formData.date"
              :is-time-disabled="isDisabled"
              :is-date-disabled="isDateDisabled"
              @confirm="onConfirm"
              :status="state.hasHolidayConflict ? 'error' : ''"
            />
          </n-form-item>
          <n-tag class="days_tag" type="info"> {{ state.days }} day(s)</n-tag>
        </n-flex>
        <holiday-conflict
          v-if="state.conflict.length > 0"
          :conflict="state.conflict"
        />
        <n-form-item label="Holiday type" path="type">
          <n-select
            v-model:value="formData.type"
            filterable
            placeholder="Please select the holiday type"
            :options="[
              { label: 'Annual Leave', value: 'annual leave' },
              { label: 'Unpaid', value: 'unpaid' },
              { label: 'Sick Leave', value: 'sick leave' },
              { label: 'Maternity Leave', value: 'maternity leave' },
              { label: 'Duvet Day', value: 'duvet day' },
              { label: 'Birthday Leave', value: 'birthday leave' },
              {
                label: 'Paternity/Maternity leave',
                value: 'paternity/maternity leave',
              },
              { label: 'Bereavement Leave', value: 'bereavement leave' },
              state?.user?.parents_days_off > 0 && {
                label: 'Parenting leave',
                value: 'parenting leave',
              },
            ]"
          />
        </n-form-item>
        <n-form-item
          v-show="shouldShowDescription"
          label="Description"
          path="description"
        >
          <n-input
            v-model:value="formData.description"
            type="textarea"
            placeholder="Provide additional details about your holiday request"
          />
        </n-form-item>
        <n-space
          item-style="width : 47.5%"
          justify="space-between"
          style="width: 100%"
        >
          <n-form-item>
            <n-button style="width: 100%" @click="upateShow">Cancel</n-button>
          </n-form-item>
          <n-form-item>
            <n-button style="width: 100%" type="primary" @click="submitForm"
              >Submit</n-button
            >
          </n-form-item>
        </n-space>
      </n-form>
    </n-modal>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from "vue";
import moment from "moment";
import { useStore } from "vuex";
import { Ban, CheckmarkCircleOutline } from "@vicons/ionicons5";
import { calculateDates } from "@/services/dates";
import HolidayConflict from "../cards/HolidayConflict.vue";
var rules = {
  type: {
    required: true,
    message: "Please select the holiday type",
    trigger: ["input", "blur"],
  },
  description: {
    required: true,
    message: "Please input your description",
    trigger: ["input", "blur"],
  },
};
var Extrarules = {
  type: {
    required: true,
    message: "Please select the holiday type",
    trigger: ["input", "blur"],
  },
};
export default {
  components: {
    Ban,
    CheckmarkCircleOutline,
    HolidayConflict,
  },
  setup() {
    var store = useStore();

    const state = reactive({
      showModal: false,
      days: null,
      user: computed(() => store.state.user),
      extraRules: false,
      hasHolidayConflict: computed(() => store.state.hasHolidayConflict),
      conflict: computed(() => store.state.holidaysConflict),
    });

    var formData = reactive({
      date: [new Date(), new Date()],
      type: "annual leave",
      description: null,
    });

    watch(() => {
      state.days = calculateDates(
        formData.date && formData.date.length > 0 ? formData.date[0] : null,
        formData.date && formData.date.length > 0 ? formData.date[1] : null
      );
    });
    var formRef = ref(null);

    var dateDisabled = (date) => {
      date = new Date(date);
      const dayOfWeek = date.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

      return dayOfWeek === 0 || dayOfWeek === 6; // Sunday (0) or Saturday (6)
    };

    var upateShow = () => {
      state.showModal = !state.showModal;
    };

    var submitForm = async (e) => {
      formRef.value
        ?.validate()
        .then(async (res) => {
          console.log(res);
          var result = await store.dispatch("requestHoliday", {
            ...formData,
            amount: state.days,
          });
          if (result) upateShow();
        })
        .catch((err) => {
          console.log(err);
        });
    };

    var isDisabled = (S) => {
      return {
        isMinuteDisabled: (s) => {
          if (s != 0) return true;
          else return false;
        },
        isSecondDisabled: (s) => {
          if (s != 0) return true;
          else return false;
        },
      };
    };

    const shouldShowDescription = computed(() => {
      const hideDescriptionForTypes = ["sick leave", "parenting leave"];
      state.extraRules = true;
      return !hideDescriptionForTypes.includes(formData.type);
    });
    var isDateDisabled = (time) => {
      if (!time) {
        return false;
      }

      const currentDate = new Date();
      const selectedDate = new Date(time);

      // Compare the date parts only
      return (
        selectedDate.getFullYear() < currentDate.getFullYear() ||
        (selectedDate.getFullYear() === currentDate.getFullYear() &&
          (selectedDate.getMonth() < currentDate.getMonth() ||
            (selectedDate.getMonth() === currentDate.getMonth() &&
              selectedDate.getDate() < currentDate.getDate())))
      );
    };

    var onConfirm = async (e) => {
      var result = await store.dispatch("fetchConflict", e);
    };

    return {
      shouldShowDescription,
      state,
      upateShow,
      dateDisabled,
      formData,
      formRef,
      rules,
      submitForm,
      isDisabled,
      Extrarules,
      isDateDisabled,
      onConfirm,
    };
  },
};
</script>

<style>
.days_tag {
}
</style>
