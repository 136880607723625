<template>
  <n-form ref="formRef" class="user_form" :model="formData">
    <n-space
      item-style="width : 100%"
      justify="space-between"
      style="width: 100%; margin-top: 1.5em"
    >
      <n-form-item label="Extra days" path="days">
        <n-input-number v-model:value="formData.days" :min="0" clearable />
      </n-form-item>
      <n-form-item label="Reason" path="reason">
        <n-input v-model:value="formData.reason" clearable />
      </n-form-item>
    </n-space>
    <n-space
      item-style="width : 47.5%"
      justify="space-between"
      style="width: 100%; margin-top: 1.5em"
    >
      <n-form-item>
        <n-button style="width: 100%" @click="cancel">Cancel</n-button>
      </n-form-item>
      <n-form-item>
        <n-button style="width: 100%" type="primary" @click="submitForm"
          >Update</n-button
        >
      </n-form-item>
    </n-space>
  </n-form>
</template>

<script>
import { reactive, ref } from "vue";
import { useStore } from "vuex";

export default {
  props: ["currentUser", "updateModal"],
  setup(props) {
    var store = useStore();
    const formData = reactive({
      days: 0,
      reason: null,
      id: props.currentUser._id,
    });
    const formRef = ref(null);

    var submitForm = async (e) => {
      e.preventDefault();
      console.log(JSON.stringify(formData));
      var result = await store.dispatch("AddExtraDaysAction", formData);
      await store.dispatch("fetchUsers");
      if (result) {
        cancel();
      }
    };
    var cancel = () => {
      props.updateModal();
    };
    return {
      formRef,
      submitForm,
      formData,
      cancel,
    };
  },
};
</script>

<style>
.n-input-number {
  width: 100% !important;
}
</style>
