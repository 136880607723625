export const state = {
  user: null,
  isLoggedIn: false,
  error_message: null,
  success_message: null,
  hasError: false,
  loading: true,
  error_title: null,
  themeMode: null,
  users: [],
  showAddUserModal: false,
  holidays: [],
  showReplyModal: false,
  reply_to: null,
  reply_action: null,
  currentHoliday: null,
  message: null,
  hasMessage: false,
  notifications: [],
  myLeaves: [],
  notificationAmount: null,
  MarkAsSeen: null,
  calander: [],
  today: [],
  calendarShowMore: false,
  Positions: null,
  holidaysConflict: [],
  hasHolidayConflict: false,
  customers: [],
  orders: [],
  payment_history: [],
  payment_values: null,
};
