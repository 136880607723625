<template>
  <div>
    <reply-holiday />
    <n-space justify="space-between">
      <n-h3>Holiday Request Management</n-h3>

    </n-space>

    <data-table
      :rows="state.holidays"
      :columns="columns"
      :loading="state.loading"
    ></data-table>
  </div>
</template>

<script>
import {
  reactive,
  computed,
  onMounted,
  h,
  defineComponent,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import TableColumns from "@/data/TableColumns";
import DataTable from "@/components/common/DataTable.vue";
import { NButton } from "naive-ui";
import ReplyHoliday from "@/components/Modals/ReplyHoliday.vue";
import { Ban, CheckmarkCircleOutline } from "@vicons/ionicons5";
export default defineComponent({
  components: { DataTable, ReplyHoliday, Ban, CheckmarkCircleOutline },
  setup() {
    var store = useStore();
    var state = reactive({
      holidays: computed(() => store.state.holidays),
      loading: true,
      dates: [new Date().getTime(), new Date().getTime()],
    });
    onUnmounted(async () => {
      await store.dispatch("RemoveHolidays");
      state.loading = true;
    });
    onMounted(async () => {
      await store.dispatch("fetchHolidays");
      state.loading = false;
    });
 
    return {
      state,
      columns: [
        ...TableColumns.adminHolidays,
        {
          title: "Action",
          key: "Action",
          width: 100,
          render(row) {
            if (row.status == "pending")
              return [
                h(
                  "div",
                  { style: "display:flex;gap:1em" },
                  h(Ban, {
                    onClick: () => {
                      store.dispatch("showReply", {
                        status: true,
                        _id: row._id,
                        action: "refuse",
                      });
                    },
                    style: "color : red ; width : 18px; cursor: pointer",
                  }),
                  h(CheckmarkCircleOutline, {
                    onClick: () => {
                      store.dispatch("showReply", {
                        status: true,
                        _id: row._id,
                        action: "accept",
                      });
                    },
                    style: "color : green ; width : 18px; cursor: pointer",
                  })
                ),
              ];
            else return;
          },
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "Brand Club | Stuff Leaves";
      },
    },
  },
});
</script>

<style></style>
