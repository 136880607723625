<template>
  <div>
    <n-space style="margin-bottom: 2em" justify="space-between">
      <n-h3>Holiday Management</n-h3>
      <div class="btn_grp">
        <n-button
          strong
          size="small"
          style="padding: 1.15em 1.5em !important"
          secondary
          type="info"
          @click="showDetailsAction"
        >
          Holidays Details
        </n-button>
        <request-modal />
      </div>
    </n-space>
    <data-table
      :rows="state.holidays"
      :columns="columns"
      :loading="state.loading"
      v-if="!state.loading"
    ></data-table>
    <cancel-holiday
      :showModal="state.showCancelModal"
      :currentHoliday="state.currentHoliday"
      :updateModal="updateModal"
    />
    <update-request-modal
      v-if="state.showUpdate"
      :showModal="state.showUpdate"
      :currentHoliday="state.currentHoliday"
      :updateModal="updateModal"
    />
    <holiday-modal :updateModal="updateModal" :show-modal="state.showDetails" />
  </div>
</template>

<script>
import TableColumns from "@/data/TableColumns";
import { computed, h, onMounted, onUnmounted, reactive } from "vue";
import { useStore } from "vuex";
import DataTable from "@/components/common/DataTable.vue";
import RequestModal from "@/components/Modals/RequestModal.vue";
import CancelHoliday from "@/components/Modals/CancelHoliday.vue";
import UpdateRequestModal from "@/components/Modals/UpdateRequestModal.vue";
import HolidayModal from "@/components/Modals/HolidayModal.vue";
import { PencilSharp, Ban } from "@vicons/ionicons5";

export default {
  components: {
    DataTable,
    RequestModal,
    CancelHoliday,
    UpdateRequestModal,
    HolidayModal,
    PencilSharp,
  },
  setup() {
    var store = useStore();
    const state = reactive({
      holidays: computed(() => store.state.holidays),
      loading: computed(() => store.state.loading),
      //loading: true,
      showCancelModal: false,
      currentHoliday: null,
      showUpdate: false,
      user: computed(() => store.state.user),
      leavesDetails: computed(() => store.state.myLeaves),
      showDetails: false,
    });
    onMounted(async () => {
      await store.dispatch("fetchMyHolidays");
      //state.loading = false;
    });

    var updateModal = () => {
      state.showCancelModal = false;
      state.currentHoliday = null;
      state.showUpdate = false;
      state.showDetails = false;
    };
    var showDetailsAction = () => {
      state.showDetails = true;
    };

    return {
      state,
      updateModal,
      showDetailsAction,
      columns: [
        ...TableColumns.employeeHolidays,
        {
          title: "Action",
          key: "Action",
          width: 100,
          render(row) {
            return [
              h(
                "div",
                { style: "display:flex;gap:1em" },
                h(PencilSharp, {
                  onClick: () => {
                    if (row.status == "rejected" || row.status == "canceled")
                      return;
                    state.showUpdate = true;
                    state.currentHoliday = row;
                  },
                  style:
                    row.status == "rejected" || row.status == "canceled"
                      ? "color : #2080f0 ; width : 18px; cursor: not-allowed; opacity : 0.5"
                      : "color : #2080f0 ; width : 18px; cursor: pointer",
                }),
                h(Ban, {
                  onClick: () => {
                    if (row.status == "rejected" || row.status == "canceled")
                      return;
                    state.showCancelModal = true;
                    state.currentHoliday = row;
                  },
                  style:
                    row.status == "rejected" || row.status == "canceled"
                      ? "color : red ; width : 18px; cursor: not-allowed; opacity : 0.5"
                      : "color : red ; width : 18px; cursor: pointer",
                })
              ),
            ];
          },
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "Brand Club | Holidays";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  justify-content: space-between;
}
.btn_grp {
  display: flex;
  gap: 1em;
}
</style>
