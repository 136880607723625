<template>
  <n-card style="margin: 15px 0" v-if="conflict.length > 0">
    This holiday has <span style="color: rgb(184, 0, 0)">conflicts</span> with
    other accepted requests
    <n-card
      style="margin: 10px 0"
      v-for="item of conflict"
      v-bind:key="item._id"
    >
      <b>{{ item.userId?.firstName }} {{ item.userId?.lastName }}</b> has
      request a holiday from
      <span style="color: grey; font-weight: 700">{{
        moment(item.startDate).format("dddd D, MMMM YYYY")
      }}</span>
      to
      <span style="color: grey; font-weight: 700">{{
        moment(item.endDate).format("dddd D, MMMM YYYY")
      }}</span>
    </n-card>
  </n-card>
</template>

<script>
import moment from "moment";
export default {
  props: ["conflict"],
  setup(props) {
    return {
      props,
      moment,
    };
  },
};
</script>

<style lang="scss" scoped></style>
