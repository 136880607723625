<template>
  <n-modal
    :show="props.showModal"
    @update:show="props.updateModal"
    class="custom-card"
    preset="card"
    title="Holidays details"
    :bordered="false"
    size="huge"
    style="width: 60%"
  >
    <n-table v-if="state.leavesDetails" :single-line="false">
      <thead>
        <tr>
          <th v-if="state.leavesDetails?.parentingLeave?.remainingHours > 0">
            Parenting Leaves
          </th>
          <th v-if="state.leavesDetails?.parentingLeave?.usedHours > 0">
            Used Hours
          </th>
          <th>Annual Leaves <span style="font-size: 10px">(Days)</span></th>
          <th>
            Booked Annual Leave <span style="font-size: 10px">(Days)</span>
          </th>
          <th>
            Used Annual Leaves <span style="font-size: 10px">(Days)</span>
          </th>
          <th>Used Sick Leave <span style="font-size: 10px">(Days)</span></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-if="state.leavesDetails?.parentingLeave?.remainingHours > 0">
            {{ state?.leavesDetails?.parentingLeave?.remainingHours }} hour{{
              state.leavesDetails?.parentingLeave?.remainingHours > 0
                ? "s"
                : null
            }}
          </td>
          <td v-if="state.leavesDetails?.parentingLeave?.usedHours > 0">
            {{ state.leavesDetails?.parentingLeave?.usedHours }}
          </td>
          <td>{{ state.leavesDetails?.remainingLeaves?.toFixed(0) }}</td>
          <td>{{ state.leavesDetails?.bookedAnnualLeave }}</td>
          <td>{{ state.leavesDetails?.usedAnnualLeaves }}</td>
          <td>{{ state.leavesDetails?.usedSickLeave }}</td>
        </tr>
      </tbody>
    </n-table>
  </n-modal>
</template>

<script>
import { reactive, toRefs, computed, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  props: ["showModal", "updateModal", "holiday"],
  setup(props) {
    const show = computed({
      get: () => props.showModal,
      set: (value) => {
        props.updateModal(value);
      },
    });
    var store = useStore();
    const state = reactive({
      leavesDetails:
        props.holiday && props.holiday.length
          ? computed(() => JSON.parse(props.holiday))
          : computed(() => store.state.myLeaves),
    });
    onMounted(async () => {
      if (!props.holiday) await store.dispatch("fetchMyLeaves");
    });

    return {
      props,
      show,
      state,
    };
  },
};
</script>

<style lang="scss" scoped></style>
