<template>
  <n-modal
    :show="show"
    @update:show="props.updateModal"
    class="custom-card"
    preset="card"
    title="Update an event"
    :bordered="false"
    size="huge"
    style="width: 20%"
    v-if="show"
  >
    <n-form ref="formRef" class="user_form" :model="formData">
      <n-form-item label="Title" path="title">
        <n-input
          v-model:value="formData.title"
          type="text"
          placeholder="Provide a title about your event"
        />
      </n-form-item>
      <n-form-item label="Holiday dates" path="date">
        <n-date-picker
          type="daterange"
          clearable
          v-model:value="formData.date"
        />
      </n-form-item>
      <n-form-item label="Description" path="description">
        <n-input
          v-model:value="formData.description"
          type="textarea"
          placeholder="Provide additional details about your event"
        />
      </n-form-item>
      <n-form-item label="" path="isRepeated">
        <n-checkbox v-model:checked="formData.isRepeated"
          >Repeated every year</n-checkbox
        >
      </n-form-item>
      <n-space
        item-style="width : 47.5%"
        justify="space-between"
        style="width: 100%"
      >
        <n-form-item>
          <n-button style="width: 100%" @click="props.updateModal"
            >Cancel</n-button
          >
        </n-form-item>
        <n-form-item>
          <n-button style="width: 100%" type="primary" @click="submitForm"
            >Submit</n-button
          >
        </n-form-item>
      </n-space>
    </n-form>
  </n-modal>
</template>

<script>
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";

export default {
  props: ["showModal", "updateModal", "data"],
  setup(props) {
    var formRef = ref(null);
    var store = useStore();
    const show = computed({
      get: () => props.showModal,
      set: (value) => {
        props.updateModal(value);
      },
    });
    console.log("this data in update =>", props.data);
    var formData = reactive({
      date: [new Date(props.data?.startDate), new Date(props.data?.endDate)],
      title: props.data?.title,
      description: props.data?.description,
      isRepeated: props.data?.isRepeated,
      _id: props.data?._id,
    });
    var submitForm = async (e) => {
      formRef.value
        ?.validate()
        .then(async (res) => {
          console.log(res);
          var result = await store.dispatch("UpdateCustomEvent", {
            ...formData,
          });
          if (result) props.updateModal();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return {
      show,
      formData,
      props,
      submitForm,
      formRef,
    };
  },
};
</script>

<style>
.user-modal {
  z-index: 0;
}
</style>
