<template>
  <div><login-nav></login-nav> <router-view /></div>
</template>

<script>
import LoginNav from "@/components/navBars/LoginNav.vue";
export default {
  components: { LoginNav },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
