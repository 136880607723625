<template>
  <div>
    <n-space justify="space-between">
      <credit-debit v-if="state.customerId" :customerId="state.customerId" />
      <div></div>
      <n-alert
        v-if="
          state.current_customer?.ExtraCrdit != null &&
          state.current_customer?.ExtraCrdit > 0
        "
        title="Customer has extra credit"
        type="success"
        ><template #icon>
          <n-icon>
            <CashIcon />
          </n-icon>
        </template>
        <n-space justify="space-between" align="center">
          <p>
            This customer has an extra credit with our company:
            <b>{{ state.current_customer.ExtraCrdit }} EUR</b>
          </p>
        </n-space>
      </n-alert>
    </n-space>
    <n-form class="payment_form">
      <n-space justify="space-between" size="large">
        <n-form-item label="Customer">
          <n-select
            v-model:value="state.customerId"
            filterable
            placeholder="Please select a customer"
            :options="state.customers"
            @update:value="fetchOrders"
            :loading="state.loadingUsers"
          />
        </n-form-item>
        <n-form-item label="Order">
          <n-select
            filterable
            :disabled="!state.orders.length"
            placeholder="Please select the Order"
            :options="state.orders"
            :loading="state.loading"
            @update:value="setSelectedOrder"
          />
        </n-form-item>
        <n-form-item label="Order's subtotal inclu VAT">
          <n-input-number
            :v-model:value="state?.order_total_vat"
            :value="state?.order_total_vat"
            disabled
            placeholder="Filled up the customer and order first"
            :loading="state.loading"
            :show-button="false"
          >
            <template #suffix>
              {{ state?.selectedOrder?.currency || "" }}
            </template>
          </n-input-number>
        </n-form-item>
        <n-form-item label="VAT (%)">
          <n-input-number
            v-model:value="state.vat"
            :disabled="!state.selectedOrder"
            placeholder="VAT Value"
            :loading="state.loading"
            :show-button="false"
            @update:value="UpdateVAT"
          >
            <template #suffix> % </template>
          </n-input-number>
        </n-form-item>
        <n-form-item label="Customer Paid amount">
          <n-input-number
            :disabled="!state.selectedOrder"
            v-model:value="state.amount_paid"
            :show-button="false"
            :loading="state.loading"
            placeholder="Paid amount"
            @update:value="updatePaidAmount"
          />
        </n-form-item>
        <n-form-item label="Payment date">
          <n-date-picker
            :disabled="!state.amount_paid"
            v-model:value="state.date"
            :loading="state.loading"
            placeholder="Payment date"
          />
        </n-form-item>
        <n-form-item label="Remaining to pay">
          <n-input-number
            v-model:value="state.remaining"
            :disabled="true"
            placeholder="Remaining to pay"
            :loading="state.loading"
            :show-button="false"
          >
            <template #suffix>
              {{ state?.selectedOrder?.currency || "" }}
            </template></n-input-number
          >
        </n-form-item>
      </n-space>
      <n-space justify="end">
        <n-form-item>
          <n-button
            @click="openPaymentModal"
            :disabled="
              (!state.customerId && !state.selectedOrder) ||
              state.current_customer.ExtraCrdit <= 0 ||
              (state.remaining <= 0 && !state.paid_amount)
            "
            type="primary"
            >Submit Payment with extra credit</n-button
          >
        </n-form-item>
        <n-form-item>
          <n-button
            @click="SubmitPayment(null)"
            type="primary"
            :disabled="
              !state.customerId || (state.remaining <= 0 && !state.paid_amount)
            "
            >Submit Payment</n-button
          >
        </n-form-item>
      </n-space>
    </n-form>
    <n-modal
      v-model:show="state.showModal"
      :mask-closable="false"
      preset="dialog"
      title="Add payment with extra credit"
      :on-update:show="upateShow"
      class="user-modal"
      id="userModal"
    >
      <n-form-item label="Order's subtotal inclu VAT">
        <n-input-number
          :v-model:value="state?.extraCreditPaid"
          :value="state?.extraCreditPaid"
          :disabled="state.current_customer.ExtraCrdit <= 0"
          placeholder="Filled up the customer and order first"
          :loading="state.loading"
          :show-button="false"
          :max="state.current_customer.ExtraCrdit"
        >
          <template #suffix> EUR </template>
        </n-input-number>
      </n-form-item>
      <n-space>
        <n-form-item>
          <n-button
            @click="SubmitPayment(state.current_customer.ExtraCrdit)"
            type="primary"
            :disabled="!state.customerId"
            >Use all credit</n-button
          >
        </n-form-item>
        <n-form-item>
          <n-button
            @click="SubmitPayment(null)"
            type="primary"
            :disabled="!state.customerId"
            >Submit Payment</n-button
          >
        </n-form-item>
      </n-space>
    </n-modal>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { CashOutline as CashIcon } from "@vicons/ionicons5";
import CreditDebit from "../sections/CreditDebit.vue";

export default {
  name: "payment-form",
  components: { CreditDebit },

  setup() {
    var store = useStore();
    var state = reactive({
      customers: computed(() => store.state.customers),
      loading: false,
      customerId: null,
      orders: computed(() => store.state.orders),
      payment_values: computed(() => store.state.payment_values),
      paid_amount: null,
      selectedOrder: null,
      loadingUsers: true,
      remaining: null,
      vat: null,
      amount_paid: null,
      date: null,
      order_total_vat: null,
      current_customer: null,
      showModal: false,
      extraCreditPaid: null,
      extraPaid: null,
    });
    onMounted(async () => {
      await store.dispatch("fetchCustomers");
      state.loadingUsers = false;
    });

    var fetchOrders = async (value) => {
      state.selectedOrder = null;
      state.vat = null;
      state.amount_paid = null;
      state.date = null;
      state.order_total_vat = null;
      state.remaining = null;
      state.paid_amount = null;
      state.current_customer = state.customers.find((s) => s.value == value);
      state.loading = true;
      await store.dispatch("fetchCustomerOrders", value);
      state.loading = false;
    };

    var setSelectedOrder = async (value) => {
      await store.dispatch("fetchOrderPayment", value);
      console.log("this is valuee => ", value);
      state.selectedOrder = state.orders.find((o) => o.value === value);
      state.vat = state.payment_values.VAT;
      state.remaining = state.payment_values.remaining;
      state.order_total_vat = state.payment_values.subtotal_inclu_vat;
    };

    var updatePaidAmount = (value) => {
      state.paid_amount = value;
      var vat = state.vat && state.vat > 0 ? state.vat : 0;

      var tax = (state.selectedOrder.total * vat) / 100;

      var total = state.selectedOrder.total + tax;
      var remaining = total - (value + state.payment_values.total_payed);

      state.remaining = remaining < 0 ? 0 : remaining.toFixed(2);
      if (remaining < 0) state.extraPaid = Math.abs(remaining);
    };

    var SubmitPayment = async (extraCreditPaid) => {
      state.loading = true;
      try {
        var paid_from_extra = extraCreditPaid || state.extraCreditPaid || null;

        var payload = {
          customerId: state.customerId,
          OrderId: state.selectedOrder.value,
          VAT: state.vat,
          subTotal: state.selectedOrder.total,
          payment: state.amount_paid,
          date: state.date,
          Subtotal: state.selectedOrder.total,
          paid_from_extra,
        };

        await store.dispatch("CreatePayment", payload);
        state.showModal = false;

        var remaining = state.remaining;
        var extra = state.current_customer.ExtraCrdit;

        if (paid_from_extra) {
          extra = extra - paid_from_extra;
          remaining -= paid_from_extra;
        }

        if (state.extraPaid) {
          extra += state.extraPaid;
        }

        state.remaining = remaining;
        state.current_customer.ExtraCrdit = extra;
      } catch (error) {
        console.error("Payment submission failed:", error);
      } finally {
        state.loading = false;
      }
    };

    var UpdateVAT = (value) => {
      state.vat = value;

      var vat = value && value > 0 ? value : 0;

      var tax = (state.selectedOrder.total * vat) / 100;

      var total = state.selectedOrder.total + tax;

      state.order_total_vat = total;
      var paid = state.paid_amount ? state.paid_amount : 0;
      console.log(state.paid_amount);
      console.log("🚀 ~ UpdateVAT ~ paid=>", paid);

      var remaining = total - (state.payment_values.total_payed + paid);

      state.remaining = remaining < 0 ? 0 : remaining.toFixed(2);
    };
    var openPaymentModal = () => {
      state.showModal = true;
    };

    var upateShow = () => {
      state.showModal = false;
    };
    return {
      state,
      fetchOrders,
      setSelectedOrder,
      updatePaidAmount,
      SubmitPayment,
      UpdateVAT,
      openPaymentModal,
      upateShow,
    };
  },
};
</script>

<style>
.payment_form {
  margin-top: 38px;
}
</style>
