var createCustomEvent = async (state, payload) => {
  state.calander = [payload, ...state.calander];
};
var UpdateCustomEvent = async (state, payload) => {
  state.calander = state.calander.map((item) => {
    if (item._id == payload._id) return payload;
    else return item;
  });
};
var DeleteCustomEvent = async (state, payload) => {
  state.calander = state.calander.filter((item) => {
    if (item._id != payload) return item;
  });
};
export const EventMutations = {
  createCustomEvent,
  UpdateCustomEvent,
  DeleteCustomEvent,
};
