<template>
  <n-modal
    v-model:show="props.showModal"
    :mask-closable="false"
    preset="dialog"
    title="Confirm Holiday Request Cancellation"
    :on-update:show="props.updateModal"
    id="reply_modal"
    style="width: 550px"
  >
    <n-alert title="" type="error" style="margin: 1.5em 0">
      By submitting this form, you are confirming
      <span style="font-weight: 600; color: red">the cancellation</span> of this
      holiday, and it will no longer appear on your admin's dashboard.<br /><br />
      <span style="font-weight: 600"
        >Are you sure you want to proceed with canceling this request?</span
      >
    </n-alert>
    <n-space
      item-style="width : 47.5%"
      justify="space-between"
      style="width: 100%; margin-top: 1.5em"
    >
      <n-button style="width: 100%" @click="props.updateModal">
        Cancel
      </n-button>
      <n-button style="width: 100%" type="error" @click="cancelRequest">
        Submit
      </n-button>
    </n-space>
  </n-modal>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";

export default {
  props: ["showModal", "updateModal", "currentHoliday"],
  setup(props) {
    var store = useStore();
    var cancelRequest = async () => {
      var result = await store.dispatch("updateLeave", {
        ...props.currentHoliday,
        status: "canceled",
      });
      if (result) props.updateModal();
    };

    return {
      props,
      cancelRequest,
    };
  },
};
</script>

<style lang="scss" scoped></style>
