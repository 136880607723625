<template>
  <n-modal
    v-model:show="state.showModal"
    :mask-closable="false"
    preset="dialog"
    title="Create a New User"
    :on-update:show="upateShow"
    class="user-modal"
    id="userModal"
  >
    <new-user></new-user>
  </n-modal>
</template>

<script>
import { computed, reactive } from "vue";
import NewUser from "../forms/NewUser.vue";
import { useStore } from "vuex";

export default {
  components: { NewUser },
  setup() {
    var store = useStore();
    var upateShow = () => {
      store.dispatch("modalStatus", false);
    };
    const state = reactive({
      showModal: computed(() => store.state.showAddUserModal),
    });
    return {
      state,
      upateShow,
    };
  },
};
</script>

<style>
.user-modal {
  z-index: 0;
}
</style>
