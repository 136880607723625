<template>
  <div>
    <n-h3> Brand Club Services </n-h3>
    <all-services></all-services>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import AllServices from "@/components/cards/AllServices.vue";

export default {
  components: { AllServices },
  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "Brand Club | Services";
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
