<template>
  <n-space :wrap-item="false" :wrap="false" size="large" justify="center">
    <n-card class="collapse_title">
      <n-collapse default-expanded-names="1">
        <n-collapse-item title="Internal Services" name="1">
          <n-space :wrap-item="true" size="large" justify="start">
            <div v-for="item in filteredServices" :key="item.title">
              <services
                :link="item.link"
                :logo="item.logo"
                :title="item.name"
              ></services></div
          ></n-space>
        </n-collapse-item>
      </n-collapse>
    </n-card>
    <n-card class="collapse_title">
      <n-collapse default-expanded-names="1">
        <n-collapse-item title="External Services" name="1">
          <n-space :wrap-item="true" size="large" justify="start">
            <div v-for="item in ExternalServiceList" :key="item.title">
              <services
                :link="item.link"
                :logo="item.logo"
                :title="item.title"
              ></services></div
          ></n-space>
        </n-collapse-item>
      </n-collapse>
    </n-card>
  </n-space>
</template>

<script>
import { reactive, watch, computed } from "vue";
import Services from "@/components/cards/services.vue";
import { useStore } from "vuex";
var ExternalServiceList = [
  {
    title: "eBay",
    logo: "ebay logo.png",
    link: "https://www.ebay.com/",
  },
  {
    title: "Linnworks",
    logo: "linnworks-new-logo-dark.png",
    link: "https://linnworks.net/",
  },
  {
    title: "Merkandi UK",
    logo: "merkandi_wholesale.svg",
    link: "https://merkandi.co.uk/login",
  },
  {
    title: "ParcelStars",
    logo: "parcelStars logo.png",
    link: "https://www.parcelstars.com/users/sign_in",
  },
  {
    title: "Merkandi DE",
    logo: "merkandi_wholesale.svg",
    link: "https://merkandi.de/login",
  },
  {
    title: "Etsy",
    logo: "Etsy_logo_lg_rgb.png",
    link: "https://www.etsy.com/signin",
  },
  {
    title: "Allegro",
    logo: "alegg.png",
    link: "https://allegro.pl/logowanie",
  },
  {
    title: "Pigu.lt",
    logo: "pigu-lt-logo.png",
    link: "https://pmp.pigugroup.eu/login",
  },
  {
    title: "Amazon Seller",
    logo: "amazonseller.png",
    link: "https://sellercentral.amazon.com/",
  },
];
export default {
  components: { Services },
  setup() {
    var store = useStore();
    const state = reactive({
      user: computed(() => store.state.user),
      themeMode: computed(() => store.state.themeMode),
      whiteServiceList: [
        {
          title: "productDB",
          name: "ProductsDB",
          logo: "pdb.png",
          link: process.env.VUE_APP_BASE_CJT_HOST + "/",
        },
        {
          title: "ParcelStars Connector",
          name: "PS Connector",
          logo: "parcelStars logo.png",
          link: process.env.VUE_APP_BASE_CJT_HOST + "/parcels-connector",
        },
        {
          title: "BrandClub PriceList",
          name: "Price List",
          logo: "white_bc.png",
          link: process.env.VUE_APP_BASE_CJT_HOST + "/bc-pricelist",
        },

        {
          title: "Think Circular PriceList",
          name: "Price List",
          logo: "TC logo.jpeg",
          link: process.env.VUE_APP_BASE_CJT_HOST + "/tc-pricelist",
        },
        {
          title: "BrandClub.lt",
          name: "Brandclub.lt",
          logo: "white_bc.png",
          link: "https://brandclub.lt/wp-admin",
        },
        {
          title: "BC Function",
          name: "BC Function",
          logo: "white_bc.png",
          link: process.env.VUE_APP_BASE_CJT_HOST + "/bc-pricelist",
        },
      ],
      servicesList: [
        {
          title: "productDB",
          logo: "product DB logo.png",
          name: "ProductsDB",
          link: process.env.VUE_APP_BASE_CJT_HOST + "/",
        },
        {
          title: "ParcelStars Connector",
          logo: "parcelStars logo.png",
          name: "PS Connector",

          link: process.env.VUE_APP_BASE_CJT_HOST + "/parcels-connector",
        },
        {
          title: "BrandClub PriceList",
          name: "Price List",
          logo: "BC Logo.png",
          link: process.env.VUE_APP_BASE_CJT_HOST + "/bc-pricelist/el",
        },

        {
          title: "Think Circular PriceList",
          logo: "TC logo.jpeg",
          name: "Price List",
          link: process.env.VUE_APP_BASE_CJT_HOST + "/tc-pricelist/el",
        },
        {
          title: "BrandClub.lt",
          name: "Brandclub.lt",
          logo: "BC Logo.png",
          link: "https://brandclub.lt/wp-admin",
        },
        {
          title: "BC Function",
          name: "BC Function",
          logo: "BC Logo.png",
          link: process.env.VUE_APP_BASE_CJT_HOST + "/bc-pricelist",
        },
      ],
    });

    watch(() => {
      if (!state.user) return;
      var Link = `?bc-auth-token=${localStorage.getItem(
        "auth-token"
      )}&user_id=${state.user._id}&initials=${
        state.user.credentials
      }&uniqueCode=${
        state.user.uniqueNumber
      }&permissions=${localStorage.getItem("permissions")}`;
      state.servicesList[0].link += Link;
      var type = state.user.role == "admin" ? "admin" : "user";
      for (var i = 1; i < state.servicesList.length; i++) {
        state.servicesList[i].link +=
          "?auth-token=" + localStorage.getItem("auth-token") + "&type=" + type;
      }
      if (state.user.role != "admin") {
        state.servicesList = state.servicesList.filter((s) =>
          state.user.internalServices.includes(s.title)
        );
        ExternalServiceList = ExternalServiceList.filter((s) =>
          state.user.externalServices.includes(s.title)
        );
      }
    });
    const filteredServices = computed(() => {
      return state.themeMode !== "dark"
        ? state.servicesList
        : state.whiteServiceList;
    });
    console.log("mode", filteredServices);

    return {
      filteredServices,
      state,
      ExternalServiceList,
    };
  },
};
</script>

<style>
.collapse_title {
  width: 150%;
  height: fit-content;
}
</style>
