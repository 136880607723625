import moment from "moment";

export var calculateDates = (start, end) => {
  if (!start || !end) return 0;
  const startDate = moment(start);
  const endDate = moment(end);

  let weekdays = 0;

  while (startDate.isSameOrBefore(endDate, "day")) {
    // Check if the current day is a weekday (Monday to Friday)
    if (startDate.day() >= 1 && startDate.day() <= 5) {
      weekdays++;
    }

    startDate.add(1, "day"); // Move to the next day
  }
  return weekdays;
};
