<template>
  <n-modal
    :show="show"
    @update:show="props.updateModal"
    class="custom-card"
    preset="card"
    title="Delete Event"
    :bordered="false"
    size="huge"
    style="width: 40%"
  >
    <div class="grp">
      <small
        >Are you sure you want to delete this event from your calendar?</small
      >
      <div class="grp_btn">
        <n-button type="success" @click="DeleteAnItem"> Confirm </n-button>
        <n-button @click="props.updateModal" type="error"> Cancel </n-button>
      </div>
    </div>
  </n-modal>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  props: ["showModal", "updateModal", "id"],
  setup(props) {
    var store = useStore()
    const show = computed({
      get: () => props.showModal,
      set: (value) => {
        props.updateModal(value);
      },
    });

    var DeleteAnItem = async () => {
      var result = await store.dispatch("DeleteCustomEvent", props.id);
      if (result) props.updateModal();
    };

    return {
      show,
      props,
      DeleteAnItem,
    };
  },
};
</script>

<style>
.grp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.grp_btn {
  display: flex;
  gap: 0.5em;
}
</style>
