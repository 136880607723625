var fetchUsers = async (state, payload) => {
  state.users = payload;
};
var modalStatus = async (state, payload) => {
  state.showAddUserModal = payload;
};
var addUser = async (state, payload) => {
  state.users = [payload, ...state.users];
};
var UpdateUserAction = async (state, payload) => {
  state.users = state.users.map((item) => {
    if (item._id == payload._id) return payload;
    else return item;
  });
};
var addUserToArchive = async (state, payload) => {
  state.archivedUser = payload;
};
var fetchPosition = async (state,payload) => {
  console.log('payload',payload)
  state.Positions = payload
}

export const UsersMutations = {
  fetchUsers,
  modalStatus,
  addUser,
  UpdateUserAction,
  addUserToArchive,
  fetchPosition
};
