<template>
  <n-card title="" class="notif_container">
    <n-collapse :default-expanded-names="['1']">
      <n-collapse-item title="Notifications and Updates" name="1">
        <NotificationList :notifications="state.notifications" />
      </n-collapse-item>
    </n-collapse>
  </n-card>
</template>

<script>
import NotificationList from "@/components/cards/NotifItem.vue";
import { useStore } from "vuex";
import { computed, reactive } from "vue";

export default {
  components: {
    NotificationList,
  },
  setup() {
    var store = useStore();
    var state = reactive({
      notifications: computed(() => store.state.notifications),
    });
    // onMounted(async () => {
    //   await store.dispatch("fetchNotifications");
    // });
    console.log('this is notifications',state.notifications)
    return {
      state,
    };
  },
};
</script>

<style>
.notif_container {
  width: 100%;
}
</style>
