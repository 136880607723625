<template>
  <div>
    <n-space style="margin-bottom: 1.5em" justify="space-between">
      <n-h3> Employee Management </n-h3>
      <n-button
        @click="showAddUserModal"
        size="medium"
        strong
        secondary
        type="info"
      >
        <template #icon>
          <AddCircleOutline></AddCircleOutline>
        </template>
        New Employee
      </n-button>
    </n-space>
    <data-table
      :rows="state.users"
      :columns="columns"
      :loading="state.loading"
    ></data-table>
    <add-user></add-user>
    <update-user
      :currentUser="state.currentUser"
      :showModal="state.showUpdate"
      :updateModal="modalUpdate"
    />
    <add-extra-leaves
      :currentUser="state.currentUser"
      :showModal="state.showExtraModal"
      :updateModal="ExtraModal"
    />
    <holiday-modal
      v-if="state.showDetailsHolidays && state.currentUser"
      :holiday="JSON.stringify(state?.currentUser?.holidays)"
      :updateModal="updateModalHoliday"
      :show-modal="state.showDetailsHolidays"
    />
    <confirm-archive
      :updateModal="updateModal"
      :showModal="state.showModal"
      :confirm="confirmAction"
      :cancel="cancelAction"
      :modalTitle="'Are you sure you want to archive this user ?'"
      :modalText="'This user will set in the archive for 30 days before it will permanently be deleted from the system.'"
    />

    <confirm-restore
      :updateModal="updateModalRestore"
      :showModal="state.showModalRestore"
      :confirm="confirmUnarchivie"
      :cancel="cancelActionRestore"
      :modalTitle="'Are you sure you want to restore this user ?'"
      :modalText="'this user will be back into the system immediately.'"
    />
    <confirm-perma
      :updateModal="updateModalPerma"
      :showModal="state.showModalPerma"
      :confirm="confirmActionPerma"
      :cancel="cancelActionPerma"
      :modalTitle="'Are you sure you want to delete this user ?'"
      :modalText="'You wont be able to restore this user anymore.'"
    />
  </div>
</template>
<script>
import { computed, h, reactive, onMounted } from "vue";
import DataTable from "@/components/common/DataTable.vue";
import TableColumns from "@/data/TableColumns";
import { useStore } from "vuex";
import {
  AddCircleOutline,
  AddCircle,
  TrashOutline,
  Pencil,
  Calendar,
  PersonRemove,
  CaretDownCircle,
  PersonRemoveSharp,
  PersonAdd,
} from "@vicons/ionicons5";
import AddUser from "@/components/Modals/AddUser.vue";
import UpdateUser from "@/components/Modals/UserUpdateModal.vue";
import { NButton, NDropdown, NIcon, NSwitch, NTooltip } from "naive-ui";
import AddExtraLeaves from "@/components/Modals/AddExtraLeaves.vue";
import ConfirmArchive from "@/components/Modals/ConfirmArchive.vue";
import ConfirmPerma from "@/components/Modals/ConfirmPerma.vue";
import ConfirmRestore from "@/components/Modals/ConfirmRestore.vue";
import HolidayModal from "@/components/Modals/HolidayModal.vue";
const renderIcon = (icon) => {
  return () => {
    return h(NIcon, null, {
      default: () => h(icon),
    });
  };
};
export default {
  components: {
    ConfirmPerma,
    ConfirmRestore,
    DataTable,
    AddCircleOutline,
    TrashOutline,
    AddUser,
    UpdateUser,
    AddExtraLeaves,
    ConfirmArchive,
    AddCircle,
    CaretDownCircle,
    NTooltip,
    PersonRemoveSharp,
    HolidayModal,
  },
  setup() {
    var store = useStore();
    const state = reactive({
      loading: true,
      showModal: false,
      users: computed(() => store.state.users),
      currentUser: null,
      showUpdate: false,
      showExtraModal: false,
      showModalRestore: false,
      showModalPerma: false,
      showDetailsHolidays: false,
    });
    var updateModal = () => {
      state.showModal = false;
    };
    var updateModalPerma = () => {
      state.showModalPerma = false;
    };
    var updateModalRestore = () => {
      state.showModalRestore = false;
    };

    var updateModalPerma = () => {
      state.showModalPerma = false;
    };
    var updateModalHoliday = () => {
      state.showDetailsHolidays = false;
    };

    onMounted(async () => {
      await store.dispatch("fetchUsers");
      await store.dispatch("fetchPosition");

      state.loading = false;
    });
    var modalUpdate = () => {
      state.showUpdate = false;
    };
    var ExtraModal = () => {
      state.showExtraModal = false;
    };
    var updateModal = () => {
      state.showCancelModal = false;
      state.currentHoliday = null;
      state.showUpdate = false;
      state.showDetails = false;
      state.showModal = false;
    };
    var showDetailsAction = (row) => {
      console.log("row", row);
      state.currentUser = row;
      state.showDetailsHolidays = true;
    };

    var showAddUserModal = async () => {
      store.dispatch("modalStatus", true);
    };
    var confirmAction = async () => {
      await store.dispatch("addUserToArchive", state.currentUser._id);
      await store.dispatch("fetchUsers");
      state.showModal = false;
    };
    var confirmActionPerma = async () => {
      await store.dispatch("permaDelete", state.currentUser._id);
      await store.dispatch("fetchUsers");
      state.showModalPerma = false;
    };
    var confirmUnarchivie = async () => {
      store.dispatch("unarchiveUser", state.currentUser._id);
      await store.dispatch("fetchUsers");
      state.showModalRestore = false;
    };
    var cancelAction = () => {
      state.showModal = false;
    };
    var cancelActionRestore = () => {
      state.showModalRestore = false;
    };
    var cancelActionPerma = () => {
      state.showModalPerma = false;
    };
    var handleSelect = (row, e) => {
      console.log(row, e);
      switch (e) {
        case "update":
          state.currentUser = row;
          state.showUpdate = true;
          break;
        case "leaves":
          state.currentUser = row;
          state.showExtraModal = true;
          break;
        case "HD":
          showDetailsAction(row);
          break;
        case "Archive":
          console.log(row.id);
          state.currentUser = row;
          console.log("dd", state.currentUser);
          state.showModal = true;
          break;
        case "Unarchive":
          state.currentUser = row;
          console.log("dd", state.currentUser);
          state.showModalRestore = true;
          break;
        case "dp":
          state.currentUser = row;
          state.showModalPerma = true;

        default:
          break;
      }
    };
    return {
      cancelActionPerma,
      updateModalPerma,
      confirmActionPerma,
      updateModalPerma,
      updateModalRestore,
      confirmUnarchivie,
      updateModalHoliday,
      updateModal,
      ExtraModal,
      modalUpdate,
      updateModal,
      showDetailsAction,
      state,
      confirmAction,
      cancelAction,
      showAddUserModal,
      cancelActionRestore,
      columns: [
        ...TableColumns.usersTable,
        {
          title: "Active Account",
          key: "account-status",
          render(row) {
            if (row.deletedAt) return "-";
            return [
              h(NSwitch, {
                onClick: async () => {
                  await store.dispatch("UpdateUserAction", {
                    ...row,
                    disabled: !row.disabled,
                  });
                },
                size: "small",
                class: "Icon",
                value: !row.disabled,
              }),
            ];
          },
        },
        {
          title: "Actions",
          key: "actions",
          width: 100,
          render(row) {
            return [
              h(
                NDropdown,
                {
                  style: "cursor : pointer",
                  size: "small",
                  onSelect: (e) => {
                    handleSelect(row, e);
                  },
                  options:
                    row.deletedAt && row.deletedAt !== null
                      ? [
                          {
                            label: "Restore",
                            key: "Unarchive",
                            icon: renderIcon(PersonAdd),
                          },
                          {
                            label: "Delete permanently",
                            key: "dp",
                            icon: renderIcon(PersonRemoveSharp),
                          },
                        ]
                      : [
                          {
                            label: "Update",
                            key: "update",
                            icon: renderIcon(Pencil),
                          },
                          {
                            label: "Extra annual leaves",
                            key: "leaves",
                            icon: renderIcon(AddCircle),
                          },
                          {
                            label: "Archive",
                            key: "Archive",
                            icon: renderIcon(PersonRemove),
                          },
                          {
                            label: "Holidays details",
                            key: "HD",
                            icon: renderIcon(Calendar),
                          },
                        ],
                },
                [
                  h(CaretDownCircle, {
                    size: "small",
                    style: "width : 20px; cursor : pointer",
                  }),
                ]
              ),
            ];
          },
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "Brand Club | Users";
      },
    },
  },
};
</script>
<style lang="scss">
.Icon {
  width: 18px;
  cursor: pointer;
}
.DeleteIcon {
  width: 18px;
  cursor: pointer;
  margin: 0 1em;
}
</style>
