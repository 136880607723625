<template>
  <n-modal
    v-model:show="props.showModal"
    :mask-closable="false"
    preset="dialog"
    title="Add Extra annual leaves"
    :on-update:show="upateShow"
    class="user-modal"
    id="userModal"
  >
    <extra-leaves-form
      :updateModal="upateShow"
      :currentUser="props.currentUser"
    />
  </n-modal>
</template>

<script>
import { computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import ExtraLeavesForm from "../forms/ExtraLeavesForm.vue";

export default {
  components: { ExtraLeavesForm },
  props: ["currentUser", "updateModal", "showModal"],
  setup(props) {
    var store = useStore();
    var upateShow = () => {
      props.updateModal();
    };
    const state = reactive({ ...props.currentUser });
    return {
      state,
      upateShow,
      props,
    };
  },
};
</script>

<style>
.user-modal {
  z-index: 0;
}
</style>
