const sendLoggedIn = async (state, payload) => {
  state.user = payload;
  state.loading = false;
  state.isLoggedIn = true;
};
const logout = async (state, payload) => {
  state.isLoggedIn = false;
  state.user = null;
};
var fetchAmount = async (state, payload) => {
  state.notificationAmount = payload;
};
var MarkAsSeen = async (state, payload) => {
  state.MarkAsSeen = payload;
};
var fetchNotifications = async (state, payload) => {
  payload = payload.map((item) => {
    var tags =
      item.type == "holidays"
        ? ["#happy_holiday", "#enjoy_have_fun"]
        : item.type == "Anniversary"
        ? ["#happy_Anniversary", "#enjoy_have_fun"]
        : ["#wish_happy_birthday", "#celebrate_with_colleagues"];
    return {
      ...item,
      userName: item.user,
      topic: item.type,
      tags,
    };
  });
  state.notifications = payload;
};

export const AuthMutations = {
  sendLoggedIn,
  logout,
  fetchNotifications,
  fetchAmount,
  MarkAsSeen,
};
