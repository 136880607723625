<template>
  <n-spin :show="data.loading"
    ><n-message-provider> <main-layout></main-layout> </n-message-provider
  ></n-spin>
</template>

<script>
import { computed, reactive, onMounted } from "vue";
import MainLayout from "./Layouts/Main.vue";
import { useStore } from "vuex";

export default {
  name: "App",
  components: {
    MainLayout,
  },
  setup() {
    var store = useStore();

    var data = reactive({
      loading: computed(() => store.state.loading),
    });
    return {
      data,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "Brand Club Site";
      },
    },
  },
};
</script>

<style></style>
