<template>
  <n-switch
    v-model:value="isChecked"
    :on-update:value="toggleTheme"
    size="small"
  >
    <template #checked-icon>
      <n-icon :component="Moon" />
    </template>
    <template #unchecked-icon>
      <n-icon :component="SunnyOutline" />
    </template>
  </n-switch>
</template>

<script>
import { defineComponent, ref } from "vue";
import { SunnyOutline, Moon } from "@vicons/ionicons5";
import { darkTheme } from "naive-ui";
import { useStore } from "vuex";

export default defineComponent({
  components: { darkTheme },
  setup() {
    var store = useStore();
    var isChecked = ref(false);
    if (localStorage.getItem("mode") == "dark") {
      isChecked.value = true;
    }
    var toggleTheme = (value) => {
      if (value) {
        localStorage.setItem("mode", "dark");
        isChecked.value = true;
        store.dispatch("changeTheme", "dark");
      } else {
        localStorage.setItem("mode", "light");
        isChecked.value = false;
        store.dispatch("changeTheme", "light");
      }
      console.log(value);
    };
    return {
      toggleTheme,
      isChecked,
      SunnyOutline,
      Moon,
    };
  },
});
</script>

<style lang="scss" scoped></style>
