import axios from "axios";
export const BASE_URL = process.env.VUE_APP_BASE_BRANDCLUBSITE_BACK_URL;

export const apiGet = async (url, headers) => {
  headers = {
    ...headers,
    Authorization: "Bearer " + localStorage.getItem("auth-token"),
  };
  let instance = axios.create({
    baseURL: BASE_URL,
    headers,
  });
  return await instance.get(url);
};

export const apiPost = async (url, headers, body) => {
  headers = {
    ...headers,
    Authorization: "Bearer " + localStorage.getItem("auth-token"),
  };
  let instance = axios.create({
    baseURL: BASE_URL,
    headers,
  });
  return await instance.post(url, body);
};

export const apiDelete = async (url, headers) => {
  headers = {
    ...headers,
    Authorization: "Bearer " + localStorage.getItem("auth-token"),
  };
  let instance = axios.create({
    baseURL: BASE_URL,
    headers,
  });
  return await instance.delete(url);
};

export const apiUpdate = async (url, headers, body) => {
  headers = {
    ...headers,
    Authorization: "Bearer " + localStorage.getItem("auth-token"),
  };
  let instance = axios.create({
    baseURL: BASE_URL,
    headers,
  });
  return await instance.put(url, body);
};
