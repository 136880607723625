<template>
  <n-space justify="space-between" class="login_nav_space">
    <logo></logo>
    <theme-toggle ></theme-toggle>
   </n-space>
</template>

<script>
import Logo from "../Logo.vue";
import ThemeToggle from "../ThemeToggle.vue";
import{DocumentText} from "@vicons/ionicons5"
export default {
  components: { Logo, ThemeToggle ,DocumentText },
  setup() {
    return {DocumentText};
  },
};
</script>

<style lang="scss">
.login_nav_space {
  width: -webkit-fill-available;

  position: absolute;
  top: 0%;
  padding: 1.45em;
}

</style>
