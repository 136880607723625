<template>
  <div class="notification-list-main">
    <div v-if="notifications.length > 0" class="notification-list-main">
      <div v-for="(notification, index) in notifications" :key="index">
        <n-alert
          :title="notification.description || generateTitle(notification)"
          :type="
            notification.topic == 'birthday' || 'reply_leave'
              ? 'success'
              : 'info'
          "
          class="notification-item"
        >
          <template #title> </template>
          <template #icon>
            <n-icon>
              <RoseOutline
                v-if="
                  notification.topic == 'birthday' ||
                  notification.topic == 'anniversary'
                "
              />
              <SunnyOutline v-if="notification.topic == 'holidays'" />
              <NotificationsCircleOutline
                v-if="notification.topic == 'request_leave'"
              />
              <RoseOutline v-if="notification.topic == 'reply_leave'" />
            </n-icon>
          </template>
          <div class="notification-description">
            {{ notification.description }}
          </div>
          <div
            v-if="
              notification.topic == 'birthday' ||
              notification.topic == 'anniversary'
            "
            class="notification-tags"
          >
            <span
              v-for="(tag, tagIndex) in notification.tags"
              :key="tagIndex"
              class="notification-tag"
            >
              {{ tag }} 🚀
            </span>
          </div>
          <span class="date-notification"> {{ notification.date }} </span>
        </n-alert>
      </div>
      <n-empty
        class="small-notification-list"
        v-if="notifications.length === 0"
        description="There is no notifications"
      >
      </n-empty>
    </div>
  </div>
</template>

<script>
import { h } from "vue";
import {
  Rocket,
  RoseOutline,
  SunnyOutline,
  NotificationsCircleOutline,
} from "@vicons/ionicons5";
export default {
  props: ["notifications"],
  components: { RoseOutline, SunnyOutline, NotificationsCircleOutline },
  setup(props) {
    var generateTitle = (notif) => {
      return h(
        "p",
        { class: "notif_title" },
        (notif.userName ||
          notif.user.firstName + " " + notif.user.lastName) +
          "'s " +
          notif.topic.replace("_", " ")
      );
    };
    return {
      props,
      generateTitle,
      Rocket,
    };
  },
};
</script>

<style scoped>
.notification-list-main {
  /* Add styling for the notification list container */
  overflow-y: auto;
  height: 350px;
}

.notif_title {
  margin: 0;
}
.notification-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.notification-details {
  flex: 1;
}

.notification-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.notification-topic {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.notification-tags {
  margin-bottom: 8px;
}

.notification-tag {
  display: inline-block;
  background-color: #4d96c7;
  color: #fff;
  padding: 2px 8px;
  margin-right: 4px;
  border-radius: 4px;
}

.notification-description {
  font-size: 14px;
}
.date-notification {
  font-size: 10px;
}
</style>
