<template>
    <n-modal
    :show="show"
      @update:show="updateModal"
      class="custom-card"
      preset="card"
      :title="modalTitle"
      :bordered="false"
      size="huge"
      style="width: 40%;"
    > 
    <div class="grp">
      <small>{{ modalText }}</small>
      
      <div class="grp_btn" >
        <n-button type="success" @click="confirm">
        Confirm
      </n-button>
      <n-button @click="cancel" type="error">
        Cancel
      </n-button>
      </div>
    </div>
    </n-modal>
  </template>
  
  <script>
  import { computed } from "vue";
  
  export default {
    props: ["showModal", "updateModal","confirm","cancel","modalText","modalTitle"],
    setup(props) {
      const show = computed({
        get: () => props.showModal,
        set: (value) => {
          props.updateModal(value);
        },
      });
  
      return {
        show,
        confirm: props.confirm,
        cancel: props.cancel,
        modalText:props.modalText,
        modalTitle:props.modalTitle
      };
    },
  };
  </script>
  
  <style>
  .grp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .grp_btn {
    display: flex;
    gap:0.5em
  }
  </style>
  