import { apiDelete, apiGet, apiPost, apiUpdate } from "./api";
import { apiGetPayment, apiPostPayment } from "./payment_api";

export default {
  async Me() {
    try {
      return await apiGet("/me")
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async fetchPositions() {
    try {
      return await apiGet("/positions")
        .then((response) => {
          console.log("res", response.data.title);
          return { result: response.data };
        })
        .catch((error) => {
          console.log("this error", error);
          return error;
        });
    } catch (error) {
      return error;
    }
  },
  async ArchiveUser(param) {
    console.log("param", param);
    try {
      return await apiDelete(`/archive-users/${param}`)
        .then((res) => {
          return res;
        })
        .catch((error) => console.log("this,", error));
    } catch (error) {
      return error;
    }
  },
  async PermaDelete(param) {
    try {
      return await apiDelete(`delete-users/${param}`)
        .then((result) => {
          console.log("this,", result);
          return result;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      return error;
    }
  },
  async UnarchiveUser(param) {
    try {
      return await apiUpdate(`/restore-users/${param}`)
        .then((result) => {
          console.log("result;", result);
          return result;
        })
        .catch((error) => {
          console.log("this, ", error);
          return error;
        });
    } catch (error) {
      return error;
    }
  },
  async LoginCall(data) {
    try {
      return await apiPost("/login", {}, data)
        .then((res) => {
          return { data: res.data };
        })
        .catch((error) => {
          return { error: error.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async getUsers() {
    try {
      return await apiGet("/users")
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (err) {
      return { error: err };
    }
  },
  async restoreArchivedUser(param) {
    try {
      return await apiUpdate(`/restore-users/${param}`)
        .then((res) => {
          return { data: res };
        })
        .catch((err) => {
          error: err;
        });
    } catch (err) {
      return { error: err };
    }
  },
  async getArchivedUsers() {
    try {
      return await apiGet("/archived-users")
        .then((res) => {
          console.log("res", res);
          return { data: res.data };
        })
        .catch((err) => {
          console.log(err);
        });
    } catch {
      (error) => {
        console.log(error);
      };
    }
  },
  async getLeavesAdmin(isFromCalendar) {
    try {
      return await apiGet("/users-leaves" + "?isFromCalendar=" + isFromCalendar)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (err) {
      return { error: err };
    }
  },
  async getMyLeaves() {
    try {
      return await apiGet("/my-leaves")
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (err) {
      return { error: err };
    }
  },
  async fetchLeaves() {
    try {
      return await apiGet("/leaves/user-holidays")
        .then((res) => {
          console.log("this,res", res);
          return { data: res?.data };
        })
        .catch((err) => {
          console.log("err", err);
          return { error: err.response?.data?.message };
        });
    } catch (err) {
      return { error: err };
    }
  },
  async requestLeave(data) {
    try {
      return await apiPost("/request-leave", {}, data)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async ReplytLeave(data) {
    try {
      return await apiUpdate("/reply-leave/" + data._id, {}, data)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async UpdateLeave(data) {
    try {
      return await apiUpdate("/edit-my-leave", {}, data)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },

  async FetchReportLeave(data) {
    try {
      return await apiGet(
        "/leaves/report" +
          "?startDate=" +
          data.startDate +
          "&endDate=" +
          data.endDate,
        { responseType: "blob" }
      )
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.error };
        });
    } catch (error) {
      return { error };
    }
  },
  async addUser(data) {
    try {
      return await apiPost("/users", {}, data)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async UdpateUser(data) {
    try {
      return await apiUpdate("/users/" + data._id, {}, data)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async getNoficiation() {
    try {
      return await apiGet("/my-notifications", {}, {})
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  },
  async AddExtraDays(data) {
    try {
      return await apiPost("/leaves/extra/" + data.id, {}, data)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async fetchNotifications() {
    try {
      return await apiGet("/my-notifications", {}, {})
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async MarkAsSeen() {
    try {
      return await apiPost("/seen-my-notifications", {}, {})
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  },
  async requestRestPassword(data) {
    try {
      return await apiPost("/users/reset-token", {}, data)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async requestRestPasswordUpdate(data) {
    try {
      return await apiPost("/users/reset-password", {}, data)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async fetchConflictAPI(data) {
    try {
      return await apiPost("/leaves/user-holidays-conflict", {}, data)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async createCustomEventAPI(data) {
    try {
      return await apiPost("/events", {}, data)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async UpdateCustomEventAPI(data) {
    try {
      return await apiUpdate("/event/" + data._id, {}, data)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async DeleteCustomEventAPI(id) {
    try {
      return await apiDelete("/event/" + id)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async fetchCustomersAPI() {
    try {
      return await apiGetPayment("/users", {}, {})
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      console.log("🚀 ~ fetchCustomersAPI ~ error=>", error);
      return { error };
    }
  },
  async fetchCustomersOrdersAPI(customer_id) {
    try {
      return await apiGetPayment("/customer-orders/" + customer_id, {}, {})
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async fetchOrderPaymentAPI(order_id) {
    try {
      return await apiGetPayment("/order-payment/" + order_id, {}, {})
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async createPaymentAPI(payload) {
    try {
      return await apiPostPayment("/add-order-payment", {}, payload)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
  async addManualPaymentValueAPI(payload) {
    try {
      return await apiPostPayment("/add-credit-debit", {}, payload)
        .then((res) => {
          return { data: res.data };
        })
        .catch((err) => {
          return { error: err.response?.data?.message };
        });
    } catch (error) {
      return { error };
    }
  },
};
