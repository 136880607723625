<template>
  <n-button class="logout-button" quaternary @click="logout">
    <template #icon>
      <n-icon><LogOutOutline></LogOutOutline> </n-icon
    ></template>
    Logout
  </n-button>
</template>

<script>
import { LogOutOutline } from "@vicons/ionicons5";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  components: { LogOutOutline },
  name: "Logout",
  setup() {
    var router = useRouter();
    var store = useStore();
    var logout = () => {
      localStorage.clear("auth-token");
      store.dispatch("logout");
      router.push("/login");
    };
    return {
      logout,
    };
  },
};
</script>

<style>
.logout-button {
  width: 100%;
}
</style>
