<template>
  <div class="logo-container">
    <logo></logo>
  </div>
  <div class="navbar_button">
    <theme-toggle></theme-toggle>
    <notification-bell />
    <n-dropdown
      :options="options"
      placement="bottom-start"
      trigger="click"
      @select="handleSelect"
      size="large"
    >
      <n-button size="medium" style="border-radius: 20px; padding: 0 1.5em">
        {{ state.user.firstName }} {{ state.user.lastName }}
      </n-button>
    </n-dropdown>
  </div>
</template>

<script>
import { computed, reactive, h } from "vue";
import ThemeToggle from "@/components/ThemeToggle.vue";
import NotificationBell from "@/components/Modals/NotificationBell.vue";
import Logout from "@/components/buttons/Logout.vue";
import Logo from "@/components/Logo.vue";
import { DocumentText, PricetagsSharp } from "@vicons/ionicons5";
import { useStore } from "vuex";
import { NIcon } from "naive-ui";
export default {
  components: {
    ThemeToggle,
    NotificationBell,
    Logo,
    Logout,
    PricetagsSharp,
    DocumentText,
  },
  setup() {
    var store = useStore();

    const state = reactive({
      user: computed(() => store.state.user),
    });
    var options = [
      {
        label: "Documentation",
        icon() {
          return h(NIcon, null, {
            default: () => h(DocumentText),
          });
        },
        key: "doc",
      },
      {
        label: "Submit a ticket",
        icon() {
          return h(NIcon, null, {
            default: () => h(PricetagsSharp),
          });
        },
        key: "ticket",
      },
      {
        label: "Track tickets",
        icon() {
          return h(NIcon, null, {
            default: () => h(PricetagsSharp),
          });
        },
        key: "track-ticket",
      },
      {
        type: "divider",
        key: "d1",
      },
      {
        type: "render",
        render: () => {
          return h(Logout);
        },
      },
    ];
    var handleSelect = (e) => {
      var link =
        e === "doc"
          ? "https://doc.clickup.com/2499101/d/h/2c8gx-1101/e7d2c51f6599ac8"
          : e === "ticket"
          ? "https://forms.clickup.com/43245772/f/197r6c-1548/7UIA9F4YCMM942GWTR"
          : "https://sharing.clickup.com/43245772/gr/h/197r6c-2352/0a633dad02fd3e2";
      var a = document.createElement("a");
      a.setAttribute("href", link);
      a.setAttribute("target", "_blank");
      a.click();
      a.remove();
    };
    return {
      state,
      options,
      handleSelect,
    };
  },
};
</script>

<style>
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar_button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.docs_link {
  cursor: pointer;
}
</style>
