<template>
  <n-modal
    v-model:show="props.showModal"
    :mask-closable="false"
    preset="dialog"
    title="Modify a Holiday Request"
    :on-update:show="props.updateModal"
    id="reply_modal"
  >
    <n-form ref="formRef" class="user_form" :model="formData" :rules="rules">
      <n-flex
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1.5em;
        "
        :vertical="false"
        justify="center"
      >
        <n-form-item label="Holiday dates" path="date">
          <n-date-picker
            v-if="
              state?.user?.parents_days_off > 0 &&
              formData.type == 'parenting leave'
            "
            type="datetimerange"
            format="yyyy/MM/dd - HH"
            clearable
            v-model:value="formData.date"
            :is-time-disabled="isDisabled"
          />
          <n-date-picker
            v-else
            type="daterange"
            clearable
            v-model:value="formData.date"
            :is-time-disabled="isDisabled"
          />
        </n-form-item>
        <n-tag class="days_tag" type="info">
          {{ state.days }} day(s)</n-tag
        ></n-flex
      >

      <n-form-item label="Holiday type" path="type">
        <n-select
          v-model:value="formData.type"
          filterable
          placeholder="Please select a song"
          :options="[
            { label: 'Annual Leave', value: 'annual leave' },
            { label: 'Unpaid', value: 'unpaid' },
            { label: 'Sick Leave', value: 'sick leave' },
            { label: 'Maternity Leave', value: 'maternity leave' },
            { label: 'Duvet Day', value: 'duvet day' },
            { label: 'Birthday Leave', value: 'birthday leave' },
            {
              label: 'Paternity/Maternity leave',
              value: 'paternity/maternity leave',
            },
            { label: 'Bereavement Leave', value: 'bereavement leave' },
            state?.user?.parents_days_off > 0 && {
              label: 'Parenting leave',
              value: 'parenting leave',
            },
          ]"
        />
      </n-form-item>
      <n-form-item
        v-show="shouldShowDescription"
        label="Description"
        path="description"
      >
        <n-input
          v-model:value="formData.description"
          type="textarea"
          placeholder="Describe more your request"
        />
      </n-form-item>
      <n-space
        item-style="width : 47.5%"
        justify="space-between"
        style="width: 100%"
      >
        <n-form-item>
          <n-button style="width: 100%" @click="props.updateModal"
            >Cancel</n-button
          >
        </n-form-item>
        <n-form-item>
          <n-button style="width: 100%" type="primary" @click="submitForm"
            >Update</n-button
          >
        </n-form-item>
      </n-space>
    </n-form>
  </n-modal>
</template>

<script>
import { reactive, computed, ref, watch } from "vue";
import moment from "moment";
import { useStore } from "vuex";
import { calculateDates } from "@/services/dates";
var rules = {
  type: {
    required: true,
    message: "Please select the holiday type",
    trigger: ["input", "blur"],
  },
  description: {
    required: true,
    message: "Please input your description",
    trigger: ["input", "blur"],
  },
};
export default {
  props: ["showModal", "updateModal", "currentHoliday"],
  setup(props) {
    var store = useStore();
    const state = reactive({
      days: null,
      user: computed(() => store.state.user),
    });
    var formData = reactive({
      date: [
        new Date(props.currentHoliday.startDate),
        new Date(props.currentHoliday.endDate),
      ],
      type: props.currentHoliday.type,
      description: props.currentHoliday.description,
      _id: props.currentHoliday._id,
    });
    watch(() => {
      state.days = calculateDates(formData.date[0], formData.date[1]);
    });
    var formRef = ref(null);
    var dateDisabled = (date) => {
      date = new Date(date);
      const dayOfWeek = date.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

      return dayOfWeek === 0 || dayOfWeek === 6; // Sunday (0) or Saturday (6)
    };

    var submitForm = async (e) => {
      formRef.value
        ?.validate()
        .then(async (res) => {
          console.log(res);
          var result = await store.dispatch("updateLeave", {
            ...formData,
            amount: state.days,
          });
          if (result) props.updateModal();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    var isDisabled = (S) => {
      return {
        isMinuteDisabled: (s) => {
          if (s != 0) return true;
          else return false;
        },
        isSecondDisabled: (s) => {
          if (s != 0) return true;
          else return false;
        },
      };
    };
    const shouldShowDescription = computed(() => {
      const hideDescriptionForTypes = ["sick leave", "parenting leave"];
      return !hideDescriptionForTypes.includes(formData.type);
    });
    return {
      shouldShowDescription,
      isDisabled,
      state,
      dateDisabled,
      formData,
      formRef,
      rules,
      submitForm,
      props,
    };
  },
};
</script>

<style lang="scss" scoped></style>
