<template>
  <div>
    <n-space
      v-if="state.user && state.user.role == 'admin'"
      style="margin-bottom: 2em"
      justify="space-between"
    >
      <n-button type="primary" :onclick="updateModalStatus"
        >Create a new event</n-button
      >
      <n-button type="info" :onclick="DownloadReportAction"
        >Download Reports</n-button
      >
    </n-space>

    <Qalendar
      v-on:edit-event="editEvent"
      :events="state.allEvents"
      :config="config"
      v-on:delete-event="DeleteEvent"
    >
      <template #weekDayEvent="eventProps">
        <div
          :style="{
            backgroundColor: 'yellow',
            color: '#fff',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }"
        >
          <span>{{ eventProps.eventData.title }}</span>
        </div>
      </template>
      <template #monthEvent="monthEventProps">
        <n-tag
          :type="
            getTagType(
              monthEventProps.eventData.calander_type,
              monthEventProps.eventData.status
            )
          "
          style="font-size: 10px; width: 100%"
          >{{ monthEventProps.eventData.title }}</n-tag
        >
      </template>
    </Qalendar>
    <UpdateCustomEvent
      v-if="state.showUpdate && state.currentEvent != null"
      :showModal="state.showUpdate"
      :updateModal="updateEventModal"
      :data="state.currentEvent"
    />
    <custom-event
      :showModal="state.showModal"
      :updateModal="updateModalStatus"
    />

    <ConfirmDeleteEvent
      :showModal="state.showDelete"
      :updateModal="updateDeleteStatus"
      :id="state.id"
    />
    <DownloadReport
      :showModal="state.download"
      :updateModal="DownloadReportAction"
    />
  </div>
</template>

<script lang="js">
import { computed,   reactive, onMounted,  watch } from "vue";
import { useStore } from "vuex";
import { Qalendar } from "qalendar";
import moment from "moment";
import CustomEvent from "./../components/Modals/CustomEvent"
import UpdateCustomEvent from "./../components/Modals/UdpateCustomEvent.vue"
import ConfirmDeleteEvent from "@/components/Modals/ConfirmDeleteEvent.vue";
import DownloadReport from "./../components/Modals/DownloadReport.vue";
export default {
    setup() {
        var store = useStore();
        const state = reactive({
            leaves: computed(() => store.state.calander),
            allEvents : [],
            showModal : false,
            showUpdate : false,
            currentEvent : null,
            showDelete  : false,
            id : null,
            user : computed(() => store.state.user),
            download : false
        });


        onMounted(async () => {
      await store.dispatch("fetchCalander");
    });


    var DownloadReportAction = () => {
      state.download = !state.download
    }


    var updateDeleteStatus = () => {
      state.showDelete = !state.showDelete
    }
    var updateEventModal = ( ) => {
      state.showUpdate = !state.showUpdate

    }
var updateModalStatus = async () => {
  state.showModal = !state.showModal
}
var getTagType = (type , status) => {
if (type == "event") {
  return 'info'
}
if (type == "birthday" || type == "anniversary") {
  return "warning"
}else if (type == "leaves") {
 if (status == 'accepted') return "success"
else return "info"
}else return "info"

}
    function getColorBasedOnStatus(status) {
      var color = ""
    switch (status) {
        case 'approved':
        color = 'green';
        break

        case 'rejected':
        color = 'red';
        break

case 'canceled':
color = 'red';
break

            case "birthday" :
            color = 'green';
            break

            case "anniversary" :
            color = 'yellow';
            break

        default:
        color = 'blue';
        break
    }
    return color
  }
  watch(() => {
    if (state.leaves) {
      var frontendEvents = state.leaves.map(leave => ({
    title:  leave.calander_type == "leaves" ? leave.user + ": " + (leave.type) : leave.calander_type == "event" ? leave.title :  leave.user + "'s "+  leave.calander_type,
    with: leave.calander_type != "event"  ? leave.user : null,
    time: {
        start: moment(leave.startDate).format("YYYY-MM-DD"),
        end: moment(leave.endDate).format("YYYY-MM-DD"),
    },
 color: leave.calander_type == "leaves" ?  getColorBasedOnStatus(leave.status) :getColorBasedOnStatus(leave.calander_type) ,
    isEditable:leave.calander_type == "event" && state.user && state.user.role == "admin" ? true :  false,
    id: leave._id,
    description: leave.description || "",
    topic : leave.calander_type,
    isCustom: true,
    calander_type : leave.calander_type,
    status : leave.status

}));

state.allEvents = frontendEvents.filter(ev => {
  if (ev.calander_type == "leaves"&& (ev.status == "canceled" || ev.status == "rejected" || ev.status == "pending")  )return false
  else return ev
})
    }
  } )


 var editEvent = async (data) => {
var currentEvent  = state.leaves.find( e => e._id == data)
state.currentEvent = currentEvent
state.showUpdate = true
 }
 var DeleteEvent = async (data) => {
  console.log("this is data => " , data)
state.id = data
state.showDelete = true
 }
        return {
          DeleteEvent,
          editEvent,
          updateModalStatus,
            state,
            config : {
              week: {
        startsOn: 'monday',
        nDays: 7,
        scrollToHour: 5,
      },
      month: {
      },
      locale: 'en-EN',

      defaultMode: 'month',
      isSilent: true,
      showCurrentTime: true, // Display a line indicating the current time
            },
            getTagType,
            updateEventModal,
            updateDeleteStatus,
            DownloadReportAction
        };
    },
    components: {  Qalendar, CustomEvent , UpdateCustomEvent , ConfirmDeleteEvent ,  DownloadReport   },
    watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "Brand Club | Calendar";
      },
    },
  },
}
</script>

<style>
@import "/node_modules/qalendar/dist/style.css";
.calendar-root-wrapper {
  height: 750px !important;
}
</style>
