<template>
  <div v-if="state.payment_history.length > 0">
<h4>Customer's payment history on this order</h4>
    <n-timeline >
        <n-timeline-item
          v-for="line of state.payment_history"
          :key="line._id"
          type="success"
          :title="'Paid: ' + line.payment + '$'"
          :time="refineDate(line.date)"
          :content="line.isFromExtraCredit && 'From credit'" 
        >
          <template #icon>
            <n-icon>
              <CashIcon />
            </n-icon>
          </template>
        </n-timeline-item>
      </n-timeline>
  </div>
</template>

<script>
import moment from "moment";
import { computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { CashOutline as CashIcon } from "@vicons/ionicons5";

export default {
  components: { CashIcon },
  setup() {
    var store = useStore();
    const state = reactive({
      payment_history: computed(() => store.state.payment_history),
    });
    var refineDate = (date) => {
      return moment(date).format("DD dddd MMMM YYYY");
    };
    return {
      state,
      refineDate,
    };
  },
};
</script>

<style lang="scss" scoped></style>
