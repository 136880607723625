import http from "@/services/http";

var fetchUsers = async (context, payload) => {
  var { data, error } = await http.getUsers();
  if (error || !data) {
    context.commit("sendErrorMessage", error);

    return false;
  }
  context.commit("fetchUsers", data);
  return true;
};

var restoreArchivedUsers = async (context, payload) => {
  var { data, error } = await http.restoreArchivedUser(payload);
  if (error || !data) {
    context.commit("sendErrorMessage", error);
    return false;
  }
  context.commit("sendMessage", "User restored");
  return true;
};
var fetchPosition = async (context,payload) => {
  var {result, error} = await http.fetchPositions();
  if(error) {
    context.commit('sendErrorMessage',error);
  }
  context.commit("fetchPosition",result);
  return true;
};
var modalStatus = async (context, payload) => {
  context.commit("modalStatus", payload);
};
var addUser = async (context, payload) => {
  var { data, error } = await http.addUser(payload);
  if (error || !data) {
    context.commit("sendErrorMessage", error);
    return false;
  }
  context.commit("addUser", data);
  context.commit("sendMessage", "User has been created !");
  return true;
};
var UpdateUserAction = async (context, payload) => {
  var { data, error } = await http.UdpateUser(payload);
  if (error || !data) {
    context.commit("sendErrorMessage", error);
    return false;
  }
  context.commit("UpdateUserAction", data);
  context.commit("sendMessage", "User has been updated !");
  return true;
};
var addUserToArchive = async (context, payload) => {
  var { res, error } = await http.ArchiveUser(payload);
  if (error) {
    console.log("this is ", error);
    context.commit("sendErrorMessage", error);
    return false;
  }
  context.commit("sendMessage", "User has been archived ! ");
  return true;
};
var unarchiveUser = async (context,payload) => {
  var {result, error} = await http.UnarchiveUser(payload);
  if(error) {
    console.log('this dazda',error)
    context.commit('sendErrorMessage',error);
    return false;
  }
  console.log('this result',result)
  context.commit('sendMessage','User has been unarchived')
  return true
}
var permaDelete = async (context,payload) => {
  var {result,error} = await http.PermaDelete(payload);
  if(error) {
    context.commit('sendErrorMessage',error);
    return false;
  }
  context.commit("permadelete", result);
  context.commit("sendMessage", "User has been deleted permanently !");
  return true;

}
var AddExtraDaysAction = async (context, payload) => {
  var { data, error } = await http.AddExtraDays(payload);
  if (error || !data) {
    context.commit("sendErrorMessage", error);
    return false;
  }
  //context.commit("UpdateUserAction", data);
  context.commit("sendMessage", "User has been updated !");
  return true;
};
export const UsersActions = {
  fetchUsers,
  unarchiveUser,
  modalStatus,
  addUser,
  UpdateUserAction,
  AddExtraDaysAction,
  addUserToArchive,
  restoreArchivedUsers,
  permaDelete,
  fetchPosition
};
