var sendErrorMessage = async (context, payload) => {
  context.commit("sendErrorMessage", payload);
};
var sendMessage = async (context, payload) => {
  context.commit("sendMessage", payload);
};
var emptyMessages = async (context) => {
  context.commit("emptyMessages");
};
var changeTheme = async (context, payload) => {
  context.commit("changeTheme", payload);
};
export const messageActions = {
  sendErrorMessage,
  emptyMessages,
  changeTheme,
  sendMessage,
};
