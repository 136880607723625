<template>
  <n-modal
    :show="show"
    @update:show="props.updateModal"
    class="custom-card"
    preset="card"
    title="Download Report"
    :bordered="false"
    size="huge"
    style="width: 25%"
  >
    <div class="grp">
      <div style="margin-bottom: 2.5em">
        <n-h5>Select Date and Download Report</n-h5>
        <n-date-picker
          type="daterange"
          clearable
          :on-confirm="ConfirmDates"
        />
      </div>
    </div>
  </n-modal>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  props: ["showModal", "updateModal"],
  name : "download-report",
  setup(props) {
    var store = useStore();
    const show = computed({
      get: () => props.showModal,
      set: (value) => {
        props.updateModal(value);
      },
    });

    var ConfirmDates = async (data) => {
      if (!data) return;
      var fetchReport = await store.dispatch("fetchReport", data);
      if (fetchReport) {
        const blob = new Blob([fetchReport], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("target", "_blank");
        a.setAttribute("download", "leaves_report.csv");
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        props.updateModal()
      }
    };
    return {
      show,
      props,
      ConfirmDates,
    };
  },
};
</script>

<style>
.grp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.grp_btn {
  display: flex;
  gap: 0.5em;
}
</style>
