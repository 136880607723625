<template>
  <n-layout class="main-layout">
    <n-layout-header class="header" bordered>
      <nav-bar />
    </n-layout-header>
    <n-layout
      position="absolute"
      style="top: 64px; bottom: 64px;  height : 90%;"
      has-sider
    >
      <n-layout-sider
        :native-scrollbar="false"
        bordered
        show-trigger
        collapse-mode="width"
        :collapsed-width="64"
        style="width: 170px"
      >
        <n-menu
          :collapsed-width="64"
          :collapsed-icon-size="22"
          :options="menuOptions"
        />
      </n-layout-sider>
      <n-flex vertical style="width: 100%">
        <n-breadcrumb>
          <n-breadcrumb-item> Home </n-breadcrumb-item>
          <n-breadcrumb-item v-if="pageTitle != 'Home'">
            {{ pageTitle }}
          </n-breadcrumb-item>
        </n-breadcrumb>
        <n-layout
          content-style="padding: 4.5em;position:relative;"
          :native-scrollbar="false"
          class="main_layout_container"
        >
          <router-view />
        </n-layout>
      </n-flex>
    </n-layout>
    <n-layout-footer
      class="footer"
      position="absolute"
      style="padding: 1em"
      bordered
    >
      <span style="text-align: center; font-weight: 700"
        >© Brand Club {{ new Date().getFullYear() }}</span
      >
    </n-layout-footer>
  </n-layout>
</template>

<script>
import {
  NLayout,
  NLayoutHeader,
  NLayoutSider,
  NLayoutContent,
  NLayoutFooter,
  NIcon,
} from "naive-ui";
import ThemeToggle from "@/components/ThemeToggle.vue";
import NotificationBell from "@/components/Modals/NotificationBell.vue";
import { computed, h, onMounted, reactive } from "vue";
import {
  Briefcase,
  WalkSharp,
  CalendarNumber,
  Home,
  Apps,
  DocumentText,Cash
} from "@vicons/ionicons5";
import { RouterLink, useRoute } from "vue-router";
import NavBar from "@/components/common/NavBar.vue";

function renderIcon(icon) {
  return () => h(NIcon, null, { default: () => h(icon) });
}
export default {
  components: {
    NLayout,
    NLayoutHeader,
    NLayoutSider,
    NLayoutContent,
    NLayoutFooter,
    DocumentText,
    NavBar,
  },
  props: ["role"],
  setup(props) {
    var route = useRoute();
    function capitalizeFirstLetter(sentence) {
      // Split the sentence into words
      var words = sentence.split("-").join(" ");
      console.log(words);
      words = words.split(" ");
      console.log(words);
      // Capitalize the first letter of each word
      var capitalizedWords = words.map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });

      // Join the words back into a sentence
      var result = capitalizedWords.join(" ");

      return result;
    }
    var pageTitle = computed(() => capitalizeFirstLetter(route.name));
    var MenuOptionsEmployee = [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: "home",
              },
            },
            { default: () => "Home" }
          ),
        key: "go-back-home",
        icon: renderIcon(Home),
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: "my-holidays",
              },
            },
            { default: () => "My Holidays" }
          ),
        key: "my-holidays",
        icon: renderIcon(WalkSharp),
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: "Calendar",
              },
            },
            { default: () => "Calendar" }
          ),
        key: "Calendar",
        icon: renderIcon(CalendarNumber),
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: "services",
              },
            },
            { default: () => "Services" }
          ),
        key: "services",
        icon: renderIcon(Apps),
      },
    ];
    var menuOptionsAdmin = [
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: "home",
              },
            },
            { default: () => "Home" }
          ),
        key: "home",
        icon: renderIcon(Home),
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: "users",
              },
            },
            { default: () => "Employees" }
          ),
        key: "users",
        icon: renderIcon(Briefcase),
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: "my-holidays",
              },
            },
            { default: () => "My Holidays" }
          ),
        key: "my-holidays",
        icon: renderIcon(WalkSharp),
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: "staff-leave",
              },
            },
            { default: () => "Staff Leave" }
          ),
        key: "staff-leaves",
        icon: renderIcon(WalkSharp),
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: "Calendar",
              },
            },
            { default: () => "Calendar" }
          ),
        key: "Calendar",
        icon: renderIcon(CalendarNumber),
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: "services",
              },
            },
            { default: () => "Services" }
          ),
        key: "services",
        icon: renderIcon(Apps),
      },
      {
        label: () =>
          h(
            RouterLink,
            {
              to: {
                name: "customer-payment",
              },
            },
            { default: () => "Payment" }
          ),
        key: "customer-payment",
        icon: renderIcon(Cash),
      },
    ];
    var VisitForm = () => {
      var a = document.createElement("a");
      a.setAttribute(
        "href",
        "https://forms.clickup.com/43245772/f/197r6c-1548/7UIA9F4YCMM942GWTR"
      );
      a.setAttribute("target", "_blank");
      a.click();
      a.remove();
    };
    var visitDoc = () => {
      var a = document.createElement("a");
      a.setAttribute(
        "href",
        "https://doc.clickup.com/2499101/d/h/2c8gx-1101/e7d2c51f6599ac8"
      );
      a.setAttribute("target", "_blank");
      a.click();
      a.remove();
    };
    return {
      menuOptions:
        props.role == "employee" ? MenuOptionsEmployee : menuOptionsAdmin,
      pageTitle,
      DocumentText,
    };
  },
};
</script>

<style lang="scss">
.main-layout {
  height: 100vh;
}
.header {
  padding: 14px 37.5px;
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  margin: 0;
}
.loading-content {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.footer {
  text-align: center;
}
.n-breadcrumb {
  padding: 2em;
}
</style>
