<template>
  <div>
    <n-card class="employee_card" size="small">
      <p>
        By submitting this form, you are indicating your decision to
        <span style="color: green; font-weight: 700">accept</span> the request
        scheduled by
        <b style="font-weight: 700">{{ state.currentHoliday.user }}</b> as
        outlined below:
      </p>
      <p v-if="state.currentHoliday.type !== 'parenting leave'">
        From
        <span style="color: grey; font-weight: 700">{{
          moment(state.currentHoliday.startDate).format("dddd D, MMMM YYYY")
        }}</span>
        to
        <span style="color: grey; font-weight: 700">{{
          moment(state.currentHoliday.endDate).format("dddd D, MMMM YYYY")
        }}</span>
      </p>
      <p v-else>
        From
        <span style="color: grey; font-weight: 700">{{
          moment(state.currentHoliday.startDate)
            .hour(12)
            .format("dddd D, MMMM YYYY, h:mm A")
        }}</span>
        to
        <span style="color: grey; font-weight: 700">{{
          moment(state.currentHoliday.endDate)
            .hour(12)
            .format("dddd D, MMMM YYYY, h:mm A")
        }}</span>
      </p>
    </n-card>
    <holiday-conflict
      v-if="state.currentHoliday?.conflict?.length > 0"
      :conflict="state.currentHoliday?.conflict"
    />
    <n-input
      v-model:value="state.comment"
      type="textarea"
      placeholder="Leave a comment"
    />
    <n-space
      item-style="width : 47.5%"
      justify="space-between"
      style="width: 100%; margin-top: 1.5em"
    >
      <n-button @click="upateShow" style="width: 100%"> Cancel </n-button>
      <n-button @click="updateStatus" type="success" style="width: 100%">
        Accept
      </n-button>
    </n-space>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import HolidayConflict from "../cards/HolidayConflict.vue";
export default {
  components: { HolidayConflict },
  setup() {
    var store = useStore();
    const state = reactive({
      currentHoliday: computed(() => store.state.currentHoliday),
      comment: null,
    });
    var upateShow = () => {
      store.dispatch("showReply", { status: false, _id: null, action: null });
    };
    var updateStatus = async () => {
      var result = await store.dispatch("replyHoliday", {
        _id: state.currentHoliday._id,
        comment: state.comment,
        status: "accepted",
      });
      console.log(result);
      if (result) upateShow();
    };
    return {
      state,
      moment,
      upateShow,
      updateStatus,
    };
  },
};
</script>

<style>
.employee_card {
  padding: 0 !important;
  margin: 1em 0;
}
.header_reply {
  margin: 1.5em 0;
}
</style>
