import { createApp } from "vue";
import App from "./App.vue";
import router from "./routers";
import store from "./store/index";
import naive from "naive-ui";
import "@/styles/global.scss"
export const app = createApp(App);
app.use(router);
app.use(store);
app.use(naive)
app.mount("#app");
