<template>
  <div class="login_container">
    <n-card size="small" class="login_card">
      <n-form ref="formRef" :model="state" :rules="rules">
        <n-form-item path="email" label="Email">
          <n-input
            v-model:value="state.email"
            @keydown.enter.prevent
            placeholder="example@brandclub.lt"
          />
        </n-form-item>
        <n-form-item first path="password" label="password">
          <n-input
            v-model:value="state.password"
            type="password"
            @keydown.enter.prevent
        /></n-form-item>
        <div>
          <n-checkbox v-model:checked="state.keepMe">
            keep me logged in
          </n-checkbox>
        </div>
        <n-button
          :disabled="
            state.password == null ||
            state.email == null ||
            state.password.length == 0 ||
            state.email.length == 0
          "
          round
          class="login_button"
          type="primary"
          @click="SubmitLogin"
        >
          Login
        </n-button>
        <center>
          <router-link to="/request-rest">Forget password ?</router-link>
        </center>
      </n-form>
    </n-card>
  </div>
</template>

<script>
import { reactive } from "vue";
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
var rules = {
  email: [
    {
      required: true,
      validator(rule, value) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        console.log(emailRegex.test(value));
        if (!value) {
          return false;
        } else if (!emailRegex.test(value)) {
          return false;
        }
        // else if (!value.includes("brandclub.lt")) {
        //   return false;
        // }
        return true;
      },
      message: "verify your email !",
    },
  ],

  password: [
     {
       validator(rule, value) {
         const passwordRegex =
           /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{12,}$/;
       console.log(passwordRegex.test(value));
         if (!value) {
           return false;
         } else if (!passwordRegex.test(value)) {
           return false;
         }
        // else if (!value.includes("brandclub.lt")) {
         //   return false;
         // }
        return true;
       },
       required: true,
       message: "verify your password !",
     },
  ],
};
export default {
  setup() {
    const formRef = ref(null);
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      email: null,
      password: null,
      keepMe: false,
    });
    var SubmitLogin = async () => {
      try {
        console.log(state);
        await formRef.value.validate(async (errors) => {
          if (errors) {
            console.log(errors);
          } else {
            var isLoggedIn = await store.dispatch("Login", {
              email: state.email,
              password: state.password,
              keepMe: state.keepMe,
            });
            if (isLoggedIn) {
              await store.dispatch("fetchNotifications");

              router.push("/");
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    return {
      rules,
      state,
      formRef,
      SubmitLogin,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "Brand Club | Login";
      },
    },
  },
};
</script>

<style lang="scss">
.login_button {
  margin-top: 1em;
  width: 100%;
}
.login_container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_card {
  max-width: 25vw;
  padding: 1.5em;
}
</style>
