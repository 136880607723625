<template>
  <n-modal
    v-model:show="props.showModal"
    :mask-closable="false"
    preset="dialog"
    title="Modify User Information"
    :on-update:show="upateShow"
    class="user-modal"
    id="userModal"
  >
    <update-user :updateModal="upateShow" :currentUser="props.currentUser" />
  </n-modal>
</template>

<script>
import { computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import UpdateUser from "../forms/UpdateUser.vue";

export default {
  components: { UpdateUser },
  props: ["currentUser", "updateModal", "showModal"],
  setup(props) {
    var store = useStore();
    var upateShow = () => {
      props.updateModal();
    };
    const state = reactive({ ...props.currentUser });
    return {
      state,
      upateShow,
      props,
    };
  },
};
</script>

<style>
.user-modal {
  z-index: 0;
}
</style>
